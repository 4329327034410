import { gql } from '@apollo/client';


export const ADD_TRENDING_KEYWORD_MUTATION = gql`
  mutation AddTrendingKeyword($keyword: String!, $url: String!, $category_id: Int!) {
    addTrendingKeyword(keyword: $keyword, url: $url, category_id: $category_id) {
      error
      message
    }
  }
`;

export const UPDATE_TRENDING_KEYWORD_MUTATION = gql`
  mutation UpdateTrendingKeyword($id: ID!, $keyword: String, $url: String) {
    updateTrendingKeyword(id: $id, keyword: $keyword, url: $url) {
      error
      message
    }
  }
`;

export const DELETE_TRENDING_KEYWORD_MUTATION = gql`
  mutation DeleteTrendingKeyword($id: ID!) {
    deleteTrendingKeyword(id: $id) {
      error
      message
    }
  }
`;