import { gql } from "@apollo/client";



export const CREATE_TESTIMONIAL = gql`
  mutation CreateTestimonial(
    $content: String!
    $title: String
    $name: String
    $address: String
    $profile_img: String
    $isActive: Boolean
  ) {
    createTestimonial(
      content: $content
      title: $title
      name: $name
      address: $address
      profile_img: $profile_img
      isActive: $isActive
    ) {
      error
      data {
        id
        content
        title
        name
        address
        profile_img
        isActive
      }
      message
    }
  }
`;

export const EDIT_TESTIMONIAL = gql`
  mutation EditTestimonial(
    $id: ID!
    $content: String
    $title: String
    $name: String
    $address: String
    $profile_img: String
  ) {
    editTestimonial(
      id: $id
      content: $content
      title: $title
      name: $name
      address: $address
      profile_img: $profile_img
    ) {
      error
      data {
        id
        content
        title
        name
        address
        profile_img
        isActive
      }
      message
    }
  }
`;

export const DELETE_TESTIMONIAL = gql`
  mutation DeleteTestimonial($id: ID!) {
    deleteTestimonial(id: $id) {
      error
      data {
        id
      }
      message
    }
  }
`;
