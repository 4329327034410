import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InitialState = {
  userLists: [],
  userListParams: { limit: number, search: string, sort: string, order: string, page: number },
  totalCount: number,
  userById: { id: string, firstName: string, lastName: string, email: string, phoneNumber: string, role: string },
}

export const getuserList = (state: InitialState, action: PayloadAction<{ limit: number, search: string, sort: string, order: string, page: number }>) => {
  if (action.payload === undefined) {
    state.userListParams.search = '';
    state.userListParams.order = 'ASC';
    state.userListParams.limit = 10;
    state.userListParams.sort = 'email';
  } else {
    state.userListParams.search = action.payload.search;
    state.userListParams.order = action.payload.order;
    state.userListParams.limit = action.payload.limit;
    state.userListParams.sort = action.payload.sort;
  }
};

export const userDelete = (state: InitialState, action: PayloadAction<{ id: string }>) => {
};

export const setUsersList = (state: any, action: any) => {
  // eslint-disable-next-line no-param-reassign
  state.userLists = action.payload;
};

export const setuserTotalCount = (state: any, action: any) => {
  // eslint-disable-next-line no-param-reassign
  state.totalCount = action.payload;
};

export const getUserByID = (state: InitialState, action: PayloadAction<{ id: string }>) => {
};

export const setUserByID = (state: InitialState, action: any) => {
  state.userById = action.payload;
};

export const createUser = (state: InitialState, action: PayloadAction<{ name: string, telephone: string, email: string }>) => {
};

export const EditUser = (state: InitialState, action: PayloadAction<{ id: string, firstName: string, lastName: string, email: string, phoneNumber: string, role: string, imageArray: any, deletedImageArray: any }>) => {
};

const initialState: InitialState = {
  userLists: [],
  userListParams: {
    limit: 10, search: '', sort: 'email', order: 'ASC', page: 1,
  },
  userById: {
    id: '', firstName: '', lastName: '', email: '', phoneNumber: '', role: '',
  },
  totalCount: 100,
};

const userListSlice = createSlice({
  name: 'user_list',
  initialState,
  reducers: {
    getuserList,
    setUsersList,
    createUser,
    userDelete,
    setuserTotalCount,
    getUserByID,
    setUserByID,
    EditUser,
  },
});

export default userListSlice;
