import { gql } from '@apollo/client';



export const CREATE_GOOGLE_LOCATION_ADDRESS_PARTS = gql`
  mutation createGoogleLocationAddressParts($input: GoogleLocationAddressPartsInput!) {
    createGoogleLocationAddressParts(input: $input) {
      error
      data {
        id
        location
        district
        types
        map {
          lat
          lng
        }
        placeId
        active
        meta_title
        meta_description
      }
      message
    }
  }
`;

export const UPDATE_GOOGLE_LOCATION_ADDRESS_PARTS = gql`
  mutation updateGoogleLocationAddressParts($id: ID!, $input: GoogleLocationAddressPartsInput!) {
    updateGoogleLocationAddressParts(id: $id, input: $input) {
      error
      data {
        id
        location
        district
        types
        map {
          lat
          lng
        }
        placeId
        active
        meta_title
        meta_description
      }
      message
    }
  }
`;

export const DELETE_GOOGLE_LOCATION_ADDRESS_PARTS = gql`
  mutation deleteGoogleLocationAddressParts($id: ID!) {
    deleteGoogleLocationAddressParts(id: $id) {
      error
      data {
        id
        location
        district
        types
        map {
          lat
          lng
        }
        placeId
        active
        meta_title
        meta_description
      }
      message
    }
  }
`;

