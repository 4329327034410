/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const BLOGLIST_QUERY = gql`
  query getBlogList(
    $limit: Int!
    $search: String!
    $sort: String!
    $order: String!
    $page: Int!
    $status: [String]!
  ) {
    getBlogList(
      limit: $limit
      search: $search
      sort: $sort
      order: $order
      page: $page
      status: $status
    ) {
      error
      message
      totalCount
      data {
        id
        title
        slug
        status
        user {
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const BLOGBYID_QUERY = gql`
  query getBlogById($id: String!) {
    getBlogById(id: $id) {
      error
      data {
        blog {
          id
          title
          slug
          user {
            id
          }
          status
          meta_data
          mdContent {
            id
            md_content
            html_content
            position
          }
          imageArray {
            small
            large
            medium
            tags
            title
            description
            position
            status
          }
          relatedProperty {
            id
            projectName
            metaDataDescription
            map {
              latitude
              longitude
            }
            saleOrRent
            floorNo
            additionalRooms
            possessionStatus
            furnishingStatus
            propertyType
            specifcation
            facing
            posessionYear
            descriptions
            bathrooms
            numberOfParking
            towerOrblock
            unitNo
            planName
            aboutLocality
            aboutLocalityPoints
            planDescription
            state
            district
            location
            videoLink
            createdAt
            updatedAt
            imageArray {
              small
              large
              tags
              medium
              title
              description
            }
            builder {
              id
              name
              address
              yearsOfExperience
              totalProjects
              projectsCompleted
              projectsOngoing
              operatedIn
              projectsdescription
              image {
                small
                large
                medium
              }
            }
          }
        }
      }
      message
    }
  }
`;
