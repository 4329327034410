/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InitialState = {
  authenticated: boolean;
  initialAuthentcationRequest: boolean;
  user: {
    attributes: {
      email: string;
      email_verified: boolean;
      sub: string;
    };
    id: string;
    username: string;
  } | undefined
}

const initialState: InitialState = {
  user: undefined,
  authenticated: false,
  initialAuthentcationRequest: false,
};

export const signinUser = (state: InitialState,
  action: PayloadAction<{ password: string, email: string }>) => {
};

export const logout = (state: InitialState) => state;

export const refreshOAuthTokens = (state: any, action: PayloadAction<{ refreshToken: string }>) => {
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signinUser,
    logout,
    refreshOAuthTokens,
  },
});

export default authSlice;
