/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import AppRoute from './common/routes/appRoute';
import Spinner from './components/spinner/Spinner';
import store from './store/store/store';
import Toast from './components/toast/Toast';
import client from './store/graphql/client';

function App() {
  return (
    <div className="App">
      <ApolloProvider client={client}>
        <Provider store={store}>
          <Spinner />
          <Toast />
          <BrowserRouter>
            <AppRoute />
          </BrowserRouter>
        </Provider>
      </ApolloProvider>
    </div>
  );
}

export default App;
