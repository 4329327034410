/* eslint-disable react/prop-types */
import React, { lazy, Suspense, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  withRouter,
} from 'react-router-dom';
import { storageEngine } from '../helper';

import PrivateRoute from './privateRoute';
import PublicRoute from './publicRoute';
import Loader from '../loader';
import { setTopLevelNavigation } from './navigator';

const Login = lazy(() => import('../../pages/Auth/login'));
const Layout = lazy(() => import('../../pages/Layout/layout'));

const AppRoute = withRouter(({ history }) => {


  const authenticationSuccessResponse = true;
  const authenticationErrorResponse = false;


  useEffect(() => {
    if (authenticationErrorResponse) {
      const redirectUrl = '/';
      // clearing local storage values
      storageEngine.unset('user');
      history.push(redirectUrl);
    }
  }, [authenticationErrorResponse]);

  return (
    <Router ref={setTopLevelNavigation}>
      <Suspense fallback={<Loader />}>
        <Switch>
          <PublicRoute path="/" component={Login} exact />
          <PrivateRoute component={Redirect} path="/" to="/projects" exact />
          <PrivateRoute
            component={Redirect}
            path="/dashboard"
            to="/projects"
            exact
          />
          <PrivateRoute component={Layout} path="/overview" exact />
          <PrivateRoute component={Layout} path="/projects" exact />
          <PrivateRoute component={Layout} path="/projects" exact />
          <PrivateRoute
            component={Layout}
            path="/projects/create-project"
            exact
          />
          <PrivateRoute
            component={Layout}
            path="/projects/update-project"
            exact
          />
          <PrivateRoute
            component={Layout}
            path="/projects/project-details"
            exact
          />
          <PrivateRoute component={Layout} path="/user" exact />
          <PrivateRoute component={Layout} path="/user/create-user" exact />
          <PrivateRoute component={Layout} path="/user/user-profile" exact />
          <PrivateRoute
            component={Layout}
            path="/developer/update-developer"
            exact
          />
          <PrivateRoute component={Layout} path="/developer" exact />
          <PrivateRoute
            component={Layout}
            path="/developer/create-developer"
            exact
          />
          <PrivateRoute component={Layout} path="/locations" exact />
          <PrivateRoute component={Layout} path="/locations/update-location" exact />
          <PrivateRoute component={Layout} path="/blogs" exact />
          <PrivateRoute component={Layout} path="/blogs/create-blog" exact />
          <PrivateRoute component={Layout} path="/blogs/update-blog" exact />
          <PrivateRoute component={Layout} path="/blogs/blog-details" exact />
          {/* <PrivateRoute component={Layout} path="/developer/developer-list" exact /> */}
          <PrivateRoute component={Layout} path="/settings" exact />
          <PrivateRoute component={Layout} path="/settings/aminities" exact />
          <PrivateRoute component={Layout} path="/permissions" exact />
          <PrivateRoute component={Layout} path="/settings/trending-keywords" exact />
          <PrivateRoute component={Layout} path="/settings/featured-locations" exact />

          <PrivateRoute component={Layout} path="/testimonials" exact />
          <PrivateRoute component={Layout} path="/testimonials/create-testimonial" exact />
          <PrivateRoute component={Layout} path="/testimonials/update-testimonial" exact />
          <PrivateRoute component={Layout} path="/testimonials/testimonial-details" exact />

          <PrivateRoute
            component={Layout}
            path="/settings/additional-rooms"
            exact
          />
        </Switch>
      </Suspense>
    </Router>
  );
});

export default AppRoute;
