/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const USERSLIST_QUERY = gql`
query getUserList($limit:Int!,$search:String!,$sort:String!,$order:String!,$page:Int!) {
  getUserList(limit:$limit,search:$search,sort:$sort,order:$order,page:$page){
      error
      message
      totalCount
      data{
          id
          firstName
          lastName
          role
          status
          email
          phoneNumber
          image{
            small
          }
      }
    }
}
`;

export const USERBYID_QUERY = gql`
query getUser($id:String!) {
  getUser(id:$id){
      error
    data{
      user{
        id
        email
        firstName
        lastName
        phoneNumber
        currentAddress
        permanantAddress,
        gender,
        dateOfBirth
        role
      }
      image{
        id
        small
        large
        medium
        status
    }
    }
    message
    }
}
`;
