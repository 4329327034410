import { gql } from '@apollo/client';


export const TESTIMONIAL_LIST = gql`
  query TestimonialList ($limit: Int, $search: String, $sort: String, $order: String, $page: Int) {
    testimonialList(limit: $limit, search: $search, sort: $sort, order: $order, page: $page){
      error
      listData {
        id
        content
        title
        name
        address
        profile_img
        isActive
      }
      message
      totalCount
    }
  }
`;

export const TESTIMONIAL_BY_ID = gql`
  query TestimonialById($id: ID!) {
    testimonialById(id: $id) {
      error
      data {
        id
        content
        title
        name
        address
        profile_img
        isActive
      }
      message
    }
  }
`;
