import { put, takeLatest, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { PayloadAction } from '@reduxjs/toolkit';
import settingsSlice from '../slices/settings.slice';
import loaderSlice from '../slices/loader.slice';
import { navigator } from '../../common/routes/navigator';

import {
  createamenityService, fileUpload, amenityOnlyDangerZoon, getAllAmenityService, imageLinkUpload, deleteAmenityService, createAdditionalRoomsService, getAdditionalRoomsService, deleteAdditionalRoomService, updateamenityService
} from '../graphql/services/settings.service';
import {
  CREATE_AMENITIES, CREATE_PROPERTY_IMAGE_URL, GET_ALL_AMENITIES, DELETE_AMENITIES, CREATE_ADDITIONALROOMS, GET_ADDITIONALROOMS, DELETE_ADDITIONALROOMS, UPDATE_AMENITY_MUTATION
} from '../actions/settings.actions';

function* createPreSignedUrl(action: any) {
  const values: any = action.svgArray;
  const imageName: string[] = [];
  values.length && values.map((item: any) => {
    imageName.push(item.name);
  });
  try {
    if (imageName.length) {
      const propertyData = yield call(fileUpload, { imageNames: imageName, folderName: 'svgCollection' });
      const { data: { propertyImagePreSignedUrl = {} } = {} } = propertyData;
      const { error, message, url } = propertyImagePreSignedUrl;
      if (error) {
        toast.error(message);
      } else {
        const parsedURL = JSON.parse(url);
        for (const [key, value] of Object.entries(parsedURL)) {
          const currentObject = values.filter((obj: any) => obj.name === key);
          if (currentObject.length) {
            const payload = { link: value, file: currentObject[0].image };
            const setImageUpload = yield call(amenityOnlyDangerZoon, payload);
            if (setImageUpload.status === 200) {
              const small = `${process.env.REACT_APP_SVG_COLLECTION_URL}${key}`;
              const medium = '';
              const large = '';
              const type = 'amenity';
              const { amenities_id } = action;
              const tags = null;
              yield call(imageLinkUpload, {
                small, medium, large, type, tags, amenities_id,
              });
            } else {
              console.log('some Image Upload Error');
            }
          } else {
            console.log('we cannot find such image name');
          }
        }
      }
    }
  } catch (err) {
    toast.error(err.message);
  }
}

function* createAmenities(action: PayloadAction<{
  amenityName: string
}>) {
  const values: Object = action.payload;
  try {
    yield put(loaderSlice.actions.show());
    const crtAmenity = yield call(createamenityService, values);
    const { data: { createAmenity = {} } = {} } = crtAmenity;
    // eslint-disable-next-line no-unused-vars
    const { error, message, data = {} } = createAmenity;

    if (error) {
      yield put(loaderSlice.actions.hide());
      toast.error(message);
    } else {
      const { id } = data;
      yield call(createPreSignedUrl, { ...values, amenities_id: id });
      yield put(loaderSlice.actions.hide());
      yield call(GetAllAmenities);
      toast.success('Amenity Created Successfully');
    }
  } catch (err:any) {
    yield put(loaderSlice.actions.hide());
    toast.error(err.message);
  }
}

function* updateAmenities(action: PayloadAction<{
  amenityId: String, amenityName: String, svgArray: any
}>) {
  const values: {amenityId: String, amenityName: String, svgArray: any} = action.payload;
  try {
    yield put(loaderSlice.actions.show());
    if(values?.amenityName !== ""){
      const updtAmenity = yield call(updateamenityService, values);
      const { data: { updateAmenity = {} } = {} } = updtAmenity;
      const { error, message, data = {} } = updateAmenity;
      if (error) {
        yield put(loaderSlice.actions.hide());
        toast.error(message);
      }
    }
    // eslint-disable-next-line no-unused-vars
     if(values?.svgArray[0]?.image?.type == "image/svg+xml") {
      const id = values?.amenityId;
      yield call(createPreSignedUrl, { ...values, amenities_id: id });
    }
    yield put(loaderSlice.actions.hide());
    yield call(GetAllAmenities);
    toast.success('Amenity Updated Successfully');
  } catch (err:any) {
    yield put(loaderSlice.actions.hide());
    toast.error(err.message);
  }
}

function* GetAllAmenities(action?:any) {
  const search =  action?.payload?.search || ''
  try {
    yield put(loaderSlice.actions.show());
    const developer = yield call(getAllAmenityService, {search});
    const { data: { getAllAmenity = {} } = {} } = developer;
    const {
      error, message, data,
    } = getAllAmenity;
    if (error) {
      yield put(loaderSlice.actions.hide());
      toast.error(message);
    } else {
      yield put(settingsSlice.actions.setamenityList(data));
      yield put(loaderSlice.actions.hide());
    }
  } catch (err:any) {
    yield put(loaderSlice.actions.hide());
    toast.error(err.message);
  }
}

function* DeleteAmenity(action: any) {
  const values: Object = action.payload;
  try {
    yield put(loaderSlice.actions.show());
    const deleteAmenityConst = yield call(deleteAmenityService, values);
    const { data: { deleteAmenity = {} } = {} } = deleteAmenityConst;
    const { error, message } = deleteAmenity;
    toast.success(message);
    try {
      yield put(loaderSlice.actions.show());
      const amenity = yield call(getAllAmenityService,{search:''});
      const { data: { getAllAmenity = {} } = {} } = amenity;
      const { error, message, data } = getAllAmenity;
      if (error) {
        yield put(loaderSlice.actions.hide());
        toast.error(message);
      } else {
        yield put(settingsSlice.actions.setamenityList(data));
        yield put(loaderSlice.actions.hide());
      }
    } catch (err:any) {
      yield put(loaderSlice.actions.hide());
      toast.error(err.message);
    }
    if (error) {
      yield put(loaderSlice.actions.hide());
      toast.error(message);
    } else {
      yield put(loaderSlice.actions.hide());
    }
  } catch (err:any) {
    yield put(loaderSlice.actions.hide());
    toast.error(err.message);
  }
}

function* CreateAdditionalRooms(action: PayloadAction<{
  name: string
}>) {
  const values: Object = action.payload;
  try {
    yield put(loaderSlice.actions.show());
    const AdditionalRoomsConst = yield call(createAdditionalRoomsService, values);
    const { data: { createAdditionalRooms = {} } = {} } = AdditionalRoomsConst;
    const { error, message, data = {} } = createAdditionalRooms;
    if (error) {
      yield put(loaderSlice.actions.hide());
      toast.error(message);
    } else {
      yield put(loaderSlice.actions.hide());
      yield call(GetAdditionalRooms);
      toast.success('Additional Rooms Created Successfully');
    }
  } catch (err) {
    yield put(loaderSlice.actions.hide());
    toast.error(err.message);
  }
}

function* GetAdditionalRooms() {
  try {
    yield put(loaderSlice.actions.show());
    const additionalRoomConst = yield call(getAdditionalRoomsService);
    const { data: { getAllAdditionalRooms = {} } = {} } = additionalRoomConst;
    const { error, message, data = {} } = getAllAdditionalRooms;
    if (error) {
      yield put(loaderSlice.actions.hide());
      toast.error(message);
    } else {
      yield put(settingsSlice.actions.setAdditionalRoomList(data));
      yield put(loaderSlice.actions.hide());
    }
  } catch (err) {
    yield put(loaderSlice.actions.hide());
    toast.error(err.message);
  }
}

function* DeleteAdditionalRoom(action: any) {
  const values: Object = action.payload;
  try {
    yield put(loaderSlice.actions.show());
    const deleteAdditionalRoomsConst = yield call(deleteAdditionalRoomService, values);
    const { data: { deleteAdditionalRooms = {} } = {} } = deleteAdditionalRoomsConst;
    const { error, message } = deleteAdditionalRooms;
    toast.success(message);
    try {
      yield put(loaderSlice.actions.show());
      const additionalRoomsConst = yield call(getAdditionalRoomsService);
      const { data: { getAllAdditionalRooms = {} } = {} } = additionalRoomsConst;
      const { error, message, data } = getAllAdditionalRooms;
      if (error) {
        yield put(loaderSlice.actions.hide());
        toast.error(message);
      } else {
        yield put(settingsSlice.actions.setAdditionalRoomList(data));
        yield put(loaderSlice.actions.hide());
      }
    } catch (err) {
      yield put(loaderSlice.actions.hide());
      toast.error(err.message);
    }
    if (error) {
      yield put(loaderSlice.actions.hide());
      toast.error(message);
    } else {
      yield put(loaderSlice.actions.hide());
    }
  } catch (err) {
    yield put(loaderSlice.actions.hide());
    toast.error(err.message);
  }
}

export default function* settingsSaga() {
  yield takeLatest(CREATE_PROPERTY_IMAGE_URL, createPreSignedUrl);
  yield takeLatest(CREATE_AMENITIES, createAmenities);
  yield takeLatest(UPDATE_AMENITY_MUTATION, updateAmenities);
  yield takeLatest(GET_ALL_AMENITIES, GetAllAmenities);
  yield takeLatest(DELETE_AMENITIES, DeleteAmenity);
  yield takeLatest(CREATE_ADDITIONALROOMS, CreateAdditionalRooms);
  yield takeLatest(GET_ADDITIONALROOMS, GetAdditionalRooms);
  yield takeLatest(DELETE_ADDITIONALROOMS, DeleteAdditionalRoom);
}
