/* eslint-disable import/no-unresolved */
import { all, setContext } from 'redux-saga/effects';
import userList from '../sagas/users.saga';
import propertyList from '../sagas/property.saga';
import signIn from '../sagas/auth.saga';
import client from '../graphql/client';
import developerSaga from '../sagas/developer.saga';
import settingsSaga from '../sagas/settings.saga';
import blogSaga from '../sagas/blog.saga';
import trendingKeywordSaga from '../sagas/trendingKeywords.saga';
import FeaturedLocationSaga from '../sagas/featuredLocation.saga';
import TestimonialSaga from '../sagas/testimonial.saga';
import locationSaga from '../sagas/locations.saga';

export default function* rootSaga() {
  yield setContext({ client });
  yield all([signIn(), userList(), propertyList(), developerSaga(), 
    settingsSaga(), blogSaga(), trendingKeywordSaga(), FeaturedLocationSaga(), TestimonialSaga(), locationSaga() ]);
}
