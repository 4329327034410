/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const SIGNIN_QUERY = gql`
  query Signin($email: String!, $password: String!) {
    signin(email: $email, password: $password) {
      error
      data {
        user{
          id
          email
          firstName
          lastName
          phoneNumber
          role
          currentAddress
          permanantAddress
          dateOfBirth
          gender
          createdAt
          image{
            small,
            large,
            medium
          }
        }
        accessToken
        refreshToken
      }
      message
    }
  }
`;

export const REFRESHTOKEN_QUERY = gql`
  query refreshToken($refreshToken: String) {
    refreshToken(refreshToken:$refreshToken){
        accessToken
        error
        message
      }
    }
`;