/* eslint-disable import/prefer-default-export */
import { call, getContext } from 'redux-saga/effects';
import axios from 'axios';
import {
  CREATE_USER_MUTATION,
  DELETEUSER_MUTATION,
  EDITUSER_MUTATION,
  CREATEPRESIGNURL_MUTATION,
  CREATEIMAGE_MUTATION,
} from '../mutation/users.mutation';
import { USERSLIST_QUERY, USERBYID_QUERY } from '../queries/users.query';

export function* createUserService(payload: any) {
  const client = yield getContext('client');
  const user = yield call(client.mutate, {
    mutation: CREATE_USER_MUTATION,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return user;
}

export function* getuserlist(payload: any) {
  const client = yield getContext('client');
  const user = yield call(client.query, {
    query: USERSLIST_QUERY,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return user;
}

export function* getuserbyid(payload: any) {
  const client = yield getContext('client');
  const user = yield call(client.query, {
    query: USERBYID_QUERY,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return user;
}

export function* userDeleteService(payload: any) {
  const client = yield getContext('client');
  const user = yield call(client.mutate, {
    mutation: DELETEUSER_MUTATION,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return user;
}

export function* edituser(payload: any) {
  const client = yield getContext('client');
  const user = yield call(client.mutate, {
    mutation: EDITUSER_MUTATION,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return user;
}

export const setImageUploadUsing = async (payload: any) => {
  const awsS3ImageUpload = await axios.put(payload.link, payload.file);
  return awsS3ImageUpload;
};
export function* fileUpload(payload: any) {
  const client = yield getContext('client');

  const user = yield call(client.mutate, {
    mutation: CREATEPRESIGNURL_MUTATION,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return user;
}
export function* imageLinkUpload(payload: any) {
  const client = yield getContext('client');

  const crate = yield call(client.mutate, {
    mutation: CREATEIMAGE_MUTATION,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return crate;
}
