import { gql } from '@apollo/client';

export const CREATE_PROPERTY_MUTATION = gql`
  mutation createProperty(
    $projectName: String!
    $saleOrRent: String!
    $propertyType: String!
    $propertyCategory: String
    $specifcation: String
    $posessionYear: Int
    $posessionMonth: Int
    $descriptions: String
    $metaDataDescription: String
    $areaTypePrice: [newAreaObject]!
    $propertyFaq: [faqObjectInput]
    $facing: String
    $bathrooms: Int
    $numberOfParking: Int
    $additionalRooms: [String]
    $possessionStatus: String
    $furnishingStatus: String
    $floorNo: Int
    $towerOrblock: String
    $unitNo: Int
    $aboutLocality: String
    $aboutLocalityPoints: [String]
    $map: mapInput
    $planName: String
    $planType: String
    $planDescription: String
    $aminities: [aminitiesinput]
    $state: String
    $district: String
    $location: String
    $builderId: String
    $videoLink: String
    # $isLatest: Boolean
    $isPrime: Boolean
    $isTrending: Boolean
    $locationGooglePlaceId: String
    $brochureLink: String
    $isBrochureVisible: Boolean
    $locationTags: String
    $metaTitle: String
    $propertyLocation: String
    $relatedPropertyIds: [relatedPropertyObjectInput]
    $propertySlug: String
    $searchIndex: Boolean
  ) {
    createProperty(
      projectName: $projectName
      saleOrRent: $saleOrRent
      propertyType: $propertyType
      propertyCategory: $propertyCategory
      specifcation: $specifcation
      posessionYear: $posessionYear
      posessionMonth: $posessionMonth
      descriptions: $descriptions
      metaDataDescription: $metaDataDescription
      areaTypePrice: $areaTypePrice
      propertyFaq: $propertyFaq
      facing: $facing
      bathrooms: $bathrooms
      numberOfParking: $numberOfParking
      additionalRooms: $additionalRooms
      possessionStatus: $possessionStatus
      furnishingStatus: $furnishingStatus
      floorNo: $floorNo
      towerOrblock: $towerOrblock
      unitNo: $unitNo
      aboutLocality: $aboutLocality
      aboutLocalityPoints: $aboutLocalityPoints
      map: $map
      planName: $planName
      planType: $planType
      planDescription: $planDescription
      aminities: $aminities
      state: $state
      district: $district
      location: $location
      builderId: $builderId
      videoLink: $videoLink
      # isLatest: $isLatest
      # isPrime: $isPrime
      isPrime: $isPrime
      isTrending: $isTrending
      locationGooglePlaceId: $locationGooglePlaceId
      brochureLink: $brochureLink
      isBrochureVisible: $isBrochureVisible
      locationTags: $locationTags
      metaTitle: $metaTitle
      propertyLocation: $propertyLocation
      relatedPropertyIds: $relatedPropertyIds
      propertySlug: $propertySlug
      searchIndex: $searchIndex
    ) {
      error
      data {
        property {
          projectName
          id
        }
      }
      message
    }
  }
`;

export const DELETEPROPERTY_MUTATION = gql`
  mutation deleteProperty($id: String!) {
    deleteProperty(id: $id) {
      error
      message
      data
    }
  }
`;

export const EDIT_PROJECT_MUTATION = gql`
  mutation editProperty(
    $id: String!
    $projectName: String!
    $saleOrRent: String!
    $propertyType: String!
    $specifcation: String
    $posessionYear: Int
    $posessionMonth: Int
    $descriptions: String
    $metaDataDescription: String
    $areaTypePrice: [newAreaObject]!
    $propertyFaq: [faqObjectInput]
    $facing: String
    $bathrooms: Int
    $numberOfParking: Int
    $additionalRooms: [String]
    $possessionStatus: String
    $furnishingStatus: String
    $floorNo: Int
    $towerOrblock: String
    $unitNo: Int
    $aboutLocality: String
    $aboutLocalityPoints: [String]
    $map: mapInput
    $planName: String
    $planImageUpdate: [planUpdateInput]
    $planType: String
    $planDescription: String
    $aminities: [aminitiesinput]
    $state: String
    $district: String
    $location: String
    $builderId: String
    $videoLink: String
    $deleteImageArray: [String]
    $tagUpdateImagesArray: [tagUpdateInput]
    # $isLatest: Boolean
    # $isPrime: Boolean
    $isPrime: Boolean
    $isTrending: Boolean
    $locationGooglePlaceId: String
    $brochureLink: String
    $isBrochureVisible: Boolean
    $locationTags: String
    $metaTitle: String
    $propertyLocation: String
    $relatedPropertyIds: [relatedPropertyObjectInput]
    $propertySlug: String
    $searchIndex: Boolean
  ) {
    editProperty(
      id: $id
      projectName: $projectName
      saleOrRent: $saleOrRent
      propertyType: $propertyType
      specifcation: $specifcation
      posessionYear: $posessionYear
      posessionMonth: $posessionMonth
      descriptions: $descriptions
      metaDataDescription: $metaDataDescription
      areaTypePrice: $areaTypePrice
      propertyFaq: $propertyFaq
      facing: $facing
      bathrooms: $bathrooms
      numberOfParking: $numberOfParking
      additionalRooms: $additionalRooms
      possessionStatus: $possessionStatus
      furnishingStatus: $furnishingStatus
      floorNo: $floorNo
      towerOrblock: $towerOrblock
      unitNo: $unitNo
      aboutLocality: $aboutLocality
      aboutLocalityPoints: $aboutLocalityPoints
      map: $map
      planName: $planName
      planType: $planType
      planDescription: $planDescription
      aminities: $aminities
      state: $state
      district: $district
      location: $location
      builderId: $builderId
      videoLink: $videoLink
      deleteImageArray: $deleteImageArray
      tagUpdateImagesArray: $tagUpdateImagesArray
      planImageUpdate: $planImageUpdate
      # isLatest: $isLatest
      # isPrime: $isPrime
      isPrime: $isPrime
      isTrending: $isTrending
      locationGooglePlaceId: $locationGooglePlaceId
      brochureLink: $brochureLink
      isBrochureVisible: $isBrochureVisible
      locationTags: $locationTags
      metaTitle: $metaTitle
      propertyLocation: $propertyLocation
      relatedPropertyIds: $relatedPropertyIds
      propertySlug: $propertySlug
      searchIndex: $searchIndex
    ) {
      error
      data {
        property {
          id
        }
      }
      message
    }
  }
`;

export const CREATEPRESIGNURL_MUTATION = gql`
  mutation propertyImagePreSignedUrl(
    $imageNames: [String]!
    $folderName: String!
  ) {
    propertyImagePreSignedUrl(
      imageNames: $imageNames
      folderName: $folderName
    ) {
      url
    }
  }
`;

export const CREATE_BROCHURE_PRESIGNURL_MUTATION = gql`
  mutation propertyBrochurePreSignedUrl(
    $brochureName: String!
    $folderName: String!
  ) {
    propertyBrochurePreSignedUrl(
      brochureName: $brochureName
      folderName: $folderName
    ) {
      url
    }
  }
`;

export const CREATEIMAGE_MUTATION = gql`
  mutation createImage(
    $description: String
    $property_id: String!
    $type: String!
    $small: String!
    $large: String!
    $medium: String!
    $tags: [String]
    $title: String
    $home_featured: Boolean
    $search_featured: Boolean
    $social_share_featured: Boolean
    $details_page_featured: Boolean
    $position: String
    $alt_text: String
  ) {
    createImage(
      description: $description
      property_id: $property_id
      type: $type
      small: $small
      large: $large
      medium: $medium
      tags: $tags
      title: $title
      home_featured: $home_featured
      search_featured: $search_featured
      social_share_featured: $social_share_featured
      details_page_featured: $details_page_featured
      position: $position
      alt_text: $alt_text
    ) {
      error
      message
      data {
        small
      }
    }
  }
`;

export const UPDATEIMAGE_MUTATION = gql`
  mutation updateImage(
    $description: String
    $property_id: String!
    $tagUpdateImagesArray: [tagUpdateObject]
  ) {
    updateImage(
      description: $description
      property_id: $property_id
      tagUpdateImagesArray: tagUpdateImagesArray
    ) {
      error
      message
      data {
        small
      }
    }
  }
`;

export const STATUS_CHANGE_MUTATE = gql`
  mutation propertyStatusChange(
    $id: String
    $status: String
    $rejectionMessage: String!
  ) {
    propertyStatusChange(
      id: $id
      status: $status
      rejectionMessage: $rejectionMessage
    ) {
      error
      message
      data {
        property {
          id
        }
      }
    }
  }
`;
