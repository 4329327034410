import { call, getContext } from 'redux-saga/effects';
import { CREATE_BLOG_MUTATION, CREATEIMAGE_MUTATION, UPDATEIMAGE_MUTATION, DELETE_BLOG_MUTATION, STATUS_CHANGE_MUTATE, EDIT_BLOG_MUTATION } from '../mutation/blog.mutation';
import { BLOGBYID_QUERY, BLOGLIST_QUERY } from '../queries/blog.query';

export function* createblogService(payload: any): any {
  const client = yield getContext('client');
  const blog = yield call(client.mutate, {
    mutation: CREATE_BLOG_MUTATION,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return blog;
}

export function* imageUploadBlog(payload: any): any {
  const client = yield getContext('client');

  const crate = yield call(client.mutate, {
    mutation: CREATEIMAGE_MUTATION,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return crate;
}
export function* imageUpdateBlog(payload: any): any {
  const client = yield getContext('client');

  const crate = yield call(client.mutate, {
    mutation: UPDATEIMAGE_MUTATION,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return crate;
}
export function* getbloglist(payload: any): any {
  const client = yield getContext('client');
  const user = yield call(client.query, {
    query: BLOGLIST_QUERY,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return user;
}

export function* deleteBlogService(payload: any): any {
  const client = yield getContext('client');
  const user = yield call(client.query, {
    query: DELETE_BLOG_MUTATION,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return user;
}

export function* getblogbyid(payload: any): any {
  const client = yield getContext('client');
  const user = yield call(client.query, {
    query: BLOGBYID_QUERY,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return user;
}

export function* updateblog(payload: any): any {
  const client = yield getContext('client');
  const user = yield call(client.mutate, {
    mutation: EDIT_BLOG_MUTATION,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return user;
}

export function* statusChangeService(payload: any): any {
  const client = yield getContext('client');
  const crate = yield call(client.mutate, {
    mutation: STATUS_CHANGE_MUTATE,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return crate;
}