import { call, put, takeLatest } from "redux-saga/effects";
import loaderSlice from "../slices/loader.slice";
import { toast } from "react-toastify";
import { addTrendingKeywordService, deleteTrendingKeywordService, getTrendingKeywordListService, updateTrendingKeywordService } from "../graphql/services/trendingKeyword.service";
import { CREATE_TRENDING_KEYWORD, DELETE_TRENDING_KEYWORD_BY_ID, GET_TRENDING_KEYWORD_LIST, UPDATE_TRENDING_KEYWORD } from "../actions/trendingKeyword.actions";
import keywordSlice from "../slices/trendingKeywords.slice";

const keywordListParams = {
    search: '',
    order: 'DESC',
    limit: 100,
    page: 1,
    sort: 'updatedAt',
}

function* createTrendingKeyword(action: any): any {
    const values = action.payload;
    try {
        yield put(loaderSlice.actions.show());

        const res = yield call(addTrendingKeywordService, { ...values});

        const error = res?.data?.addTrendingKeyword?.error;
        const message = res?.data?.addTrendingKeyword?.message;

        if (error) {
            yield put(loaderSlice.actions.hide());
            toast.error(message);
        } else {
            yield put(loaderSlice.actions.hide());
            toast.success('Trending Keyword has been successfully created');
            yield put(keywordSlice.actions.getKeywordList(keywordListParams))
        }
    } catch (err: any) {
        yield put(loaderSlice.actions.hide());
        toast.error(err.message);
    }
}

function* getTrendingKeywordList(action: any): any {
    const values = action.payload;
    try {
        yield put(loaderSlice.actions.show());

        const res = yield call(getTrendingKeywordListService, { ...values});

        const error = res?.data?.trendingKeywordList?.error;
        const message = res?.data?.trendingKeywordList?.message;
        const data = res?.data?.trendingKeywordList?.listData;


        if (error) {
            yield put(loaderSlice.actions.hide());
            toast.error(message);
        } else {
            yield put(loaderSlice.actions.hide());
            yield put(keywordSlice.actions.setKeywordList(data));
        }
    } catch (err: any) {
        yield put(loaderSlice.actions.hide());
        toast.error(err.message);
    }
}


function* deleteKeywordById(action: any): any {
    const values = action.payload;
    try {
        yield put(loaderSlice.actions.show());

        const res = yield call(deleteTrendingKeywordService, { ...values});

        const error = res?.data?.addTrendingKeyword?.error;
        const message = res?.data?.addTrendingKeyword?.message;


        if (error) {
            yield put(loaderSlice.actions.hide());
            toast.error(message);
        } else {
            yield put(loaderSlice.actions.hide());
            yield put(keywordSlice.actions.getKeywordList(keywordListParams));
        }
    } catch (err: any) {
        yield put(loaderSlice.actions.hide());
        toast.error(err.message);
    }
}


function* updateTrendingKeyword(action: any): any {
    const values = action.payload;
    try {
        yield put(loaderSlice.actions.show());

        const res = yield call(updateTrendingKeywordService, { ...values});

        const error = res?.data?.addTrendingKeyword?.error;
        const message = res?.data?.addTrendingKeyword?.message;

        if (error) {
            yield put(loaderSlice.actions.hide());
            toast.error(message);
        } else {
            yield put(loaderSlice.actions.hide());
            toast.success('Trending Keyword has been successfully updated');
            yield put(keywordSlice.actions.getKeywordList(keywordListParams))
        }
    } catch (err: any) {
        yield put(loaderSlice.actions.hide());
        toast.error(err.message);
    }
}


export default function* trendingKeywordSaga() {
    yield takeLatest(CREATE_TRENDING_KEYWORD, createTrendingKeyword);
    yield takeLatest(GET_TRENDING_KEYWORD_LIST, getTrendingKeywordList)
    yield takeLatest(DELETE_TRENDING_KEYWORD_BY_ID, deleteKeywordById);
    yield takeLatest(UPDATE_TRENDING_KEYWORD, updateTrendingKeyword)
}