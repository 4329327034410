import { gql } from '@apollo/client';

const TRENDING_KEYWORD_FIELDS = `
  id
  keyword
  url
  isActive
  category_name
  category_id
`;

export const TRENDING_KEYWORD_LIST_QUERY = gql`
  query TrendingKeywordList ($limit: Int, $search: String, $sort: String, $order: String, $page: Int) {
    trendingKeywordList (limit : $limit, search: $search, sort: $sort, order: $order, page: $page){
      error
      listData {
        ${TRENDING_KEYWORD_FIELDS}
      }
      message
    }
  }
`;

export const TRENDING_KEYWORD_BY_ID_QUERY = gql`
  query TrendingKeywordById($id: ID!) {
    trendingKeywordById(id: $id) {
      error
      data {
        ${TRENDING_KEYWORD_FIELDS}
      }
      message
    }
  }
`;


