import { call, getContext } from 'redux-saga/effects';
import { CREATE_FEATURED_LOCATION, DELETE_FEATURED_LOCATION, EDIT_FEATURED_LOCATION } from '../mutation/featuredLocation.mutation';
import { FEATURED_LOCATION_BY_ID, FEATURED_LOCATION_LIST } from '../queries/featuredLocation.query';

export function* addFeaturedLocationService(payload: any): any {
  const client = yield getContext('client');
  const res  = yield call(client.mutate, {
    mutation: CREATE_FEATURED_LOCATION,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return res;
}

export function* updateFeaturedLocationService(payload: any): any {
    const client = yield getContext('client');
    const res  = yield call(client.mutate, {
      mutation: EDIT_FEATURED_LOCATION,
      fetchPolicy: 'no-cache',
      variables: { ...payload },
    });
    return res;
}

export function* deleteFeaturedLocationService(payload: any): any {
    const client = yield getContext('client');
    const res  = yield call(client.mutate, {
      mutation: DELETE_FEATURED_LOCATION,
      fetchPolicy: 'no-cache',
      variables: { ...payload },
    });
    return res;
}


export function* getFeaturedLocationListService(payload: any): any {
  const client = yield getContext('client');
  const res = yield call(client.query, {
    query: FEATURED_LOCATION_LIST,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return res;
}

export function* getFeaturedLocationByIdService(payload: any): any {
    const client = yield getContext('client');
    const res = yield call(client.query, {
      query: FEATURED_LOCATION_BY_ID,
      fetchPolicy: 'no-cache',
      variables: { ...payload },
    });
    return res;
}