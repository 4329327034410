import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Map = {
  lat: number;
  lng: number;
};

type GoogleLocationAddressParts = {
  id: string;
  location: string;
  district: string;
  types: string[];
  map: Map;
  placeId: string;
  active: boolean;
  meta_title: string;
  meta_description: string;
};

type InitialState = {
  googleLocationAddressPartsList: GoogleLocationAddressParts[];
  googleLocationAddressPartsParams: { limit: number; search: string; sort: string; order: string; page: number, searchType: string };
  googleLocationAddressPartsDetail: GoogleLocationAddressParts | null;
  totalCount: number;
};

const initialState: InitialState = {
  googleLocationAddressPartsList: [],
  googleLocationAddressPartsParams: {
    search: '',
    searchType: '',
    order: 'DESC',
    limit: 10,
    page: 1,
    sort: 'createdAt',
  },
  googleLocationAddressPartsDetail: null,
  totalCount: 0,
};

const locationsSlice = createSlice({
  name: 'googleLocationAddressParts',
  initialState,
  reducers: {
    getGoogleLocationAddressPartsList(state, action: PayloadAction<{ limit: number; search: string; sort: string; order: string; page: number, searchType: string } | undefined>) {      
      console.log("-------------------------------------------");
      
      if (action.payload === undefined) {
        state.googleLocationAddressPartsParams.search = '';
        state.googleLocationAddressPartsParams.order = 'DESC';
        state.googleLocationAddressPartsParams.limit = 10;
        state.googleLocationAddressPartsParams.page = 1;
        state.googleLocationAddressPartsParams.sort = 'createdAt';
        state.googleLocationAddressPartsParams.searchType = '';
      } else {
        state.googleLocationAddressPartsParams = action.payload;
      }
    },
    setGoogleLocationAddressPartsList(state, action: PayloadAction<{ data: GoogleLocationAddressParts[]; totalCount: number }>) {
      state.googleLocationAddressPartsList = action.payload.data;
      state.totalCount = action.payload.totalCount;
    },
    getGoogleLocationAddressPartsDetail(state, action: PayloadAction<string>) {
      state.googleLocationAddressPartsDetail = null;
    },
    setGoogleLocationAddressPartsDetail(state, action: PayloadAction<GoogleLocationAddressParts>) {
      state.googleLocationAddressPartsDetail = action.payload;
    },
    createGoogleLocationAddressParts(state, action: PayloadAction<GoogleLocationAddressParts>) {},
    updateGoogleLocationAddressParts(state, action: PayloadAction<{ id: string; input: GoogleLocationAddressParts }>) {},
    deleteGoogleLocationAddressParts(state, action: PayloadAction<string>) {},
    updateGoogleLocationAddressPartsSuccess(
      state,
      action: PayloadAction<{ id: string; input: Partial<GoogleLocationAddressParts> }>
    ) {
      const { id, input } = action.payload;
      const index = state.googleLocationAddressPartsList.findIndex(
        (location) => location.id === id
      );
      if (index !== -1) {
        state.googleLocationAddressPartsList[index] = {
          ...state.googleLocationAddressPartsList[index],
          ...input,
        };
      }
    },

  },
});

export const {
  getGoogleLocationAddressPartsList,
  setGoogleLocationAddressPartsList,
  getGoogleLocationAddressPartsDetail,
  setGoogleLocationAddressPartsDetail,
  createGoogleLocationAddressParts,
  updateGoogleLocationAddressParts,
  deleteGoogleLocationAddressParts,
  updateGoogleLocationAddressPartsSuccess,
} = locationsSlice.actions;

export default locationsSlice;