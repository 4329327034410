import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InitialState = {
  blogLists: [],
  blogListParams: { limit: number, search: string, sort: string, order: string, page: number, status: string[] },
  totalCount: number,
  blogById: {},
  presignedUrl: {},
}

const initialState: InitialState = {
    blogLists: [],
    blogListParams: {
        search: '',
        order: 'DESC',
        limit: 10,
        page: 1,
        sort: 'updatedAt',
        status: ['Published']
    },
    blogById: {},
    totalCount: 0,
    presignedUrl: {},
};

/**
 * `createBlog` in **blog.saga** works when this function is called 
 */
export const createBlog = (state: InitialState, action: any) => {
};

/**
 * `getBlogList` in **blog.saga** works when this function is called 
 */
export const getBlogList = (state: InitialState, action: PayloadAction<{ limit: number, search: string, sort: string, order: string, page: number, status: string[] }>) => {
  if (action.payload === undefined) {
    state.blogListParams.search = '';
    state.blogListParams.order = 'ASC';
    state.blogListParams.limit = 10;
    state.blogListParams.sort = 'updatedAt';
  } else {
    state.blogListParams.limit = action.payload.limit;
    state.blogListParams.search = action.payload.search;
    state.blogListParams.sort = action.payload.sort;
    state.blogListParams.order = action.payload.order;
    state.blogListParams.page = action.payload.page;
    state.blogListParams.status = action.payload.status;
  }
};

export const setBlogList = (state: any, action: any) => {
  // eslint-disable-next-line no-param-reassign
  state.blogLists = action.payload;
};

export const setBlogTotalCount = (state: any, action: any) => {
  // eslint-disable-next-line no-param-reassign
  state.totalCount = action.payload;
};

/**
 * `deleteBlogItem` in **blog.saga** works when this function is called 
 */
export const deleteBlogItem = (state: InitialState, action: PayloadAction<{ id: string }>) => {
};

export const getBlogByID = (state: InitialState, action: PayloadAction<{ id: string }>) => {
};

export const setBlogByID = (state: InitialState, action: any) => {
  state.blogById = action.payload;
};

export const updateBlog = (state: InitialState, action:any) => {
};

export const uploadBlogImageContent = (state: InitialState, action:any) => {
};

/**
 * `createPresignedUrl` in **blog.saga** works when this function is called 
 */
export const createPreSignedUrl = (state: InitialState, action: any) => {
};

export const setPreSignedUrl = (state: InitialState, action: any) => {
  state.presignedUrl = action.payload;
};

export const statusChange = (state: InitialState, action: any) => {
};

const blogListSlice = createSlice({
  name: 'blog_list',
  initialState,
  reducers: {
    createBlog,
    getBlogList,
    setBlogList,
    setBlogTotalCount,
    deleteBlogItem,
    getBlogByID,
    setBlogByID,
    updateBlog,
    uploadBlogImageContent,
    createPreSignedUrl,
    setPreSignedUrl,
    statusChange,
  },
});

export default blogListSlice;