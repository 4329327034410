import { put, takeLatest, call, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { PayloadAction } from '@reduxjs/toolkit';
import blogListSlice from '../slices/blog.slice';
import loaderSlice from '../slices/loader.slice';
import { navigator } from '../../common/routes/navigator';
import { createblogService, imageUploadBlog, deleteBlogService, getblogbyid, getbloglist, statusChangeService, updateblog } from '../graphql/services/blog.service';
import { fileUpload, setImageUploadUsing } from '../graphql/services/property.service';
import { imageLinkUpload } from '../graphql/services/image.service';
import { CREATE_BLOG, CREATE_BLOG_IMAGE_URL, DELETE_BLOG, EDIT_BLOG, GET_BLOGS, GET_BLOG_BY_ID, STATUS_CHANGE, UPLOAD_BLOG_IMAGE } from '../actions/blog.actions';

function* createblog(action: any): any {
    const values = action.payload;
    try {
        yield put(loaderSlice.actions.show());
        const relatedProperty = values?.relatedProperty.map((elm: any) => elm.id);

        const crtBlog = yield call(createblogService, { ...values, relatedProperty });
        const { data: { createBlog = {} } = {} } = crtBlog;
        // eslint-disable-next-line no-unused-vars
        const { error, message, data = {} } = createBlog;

        if (error) {
            yield put(loaderSlice.actions.hide());
            toast.error(message);
        } else {
            const { blog } = data;
            if (values?.imageArray[0]?.blobUrl != '') {
                yield call(createPreSignedUrl, { ...values, blog_id: blog.id });
            }
            yield put(loaderSlice.actions.hide());
            toast.success('Blog created successful');
            navigator.history.replace('/blogs');
        }
    } catch (err: any) {
        yield put(loaderSlice.actions.hide());
        toast.error(err.message);
    }
}

function* getblogList(action: PayloadAction<{ limit: number, search: string, sort: string, order: string, page: number, status: string[] }>): any {
    const values: Object = action.payload;
    try {
        yield put(loaderSlice.actions.show());
        const blogList = yield call(getbloglist, values);
        const { data: { getBlogList = {} } = {} } = blogList;
        const { error, message, data, totalCount } = getBlogList;
        if (error) {
            yield put(loaderSlice.actions.hide());
            toast.error(message);
        } else {
            yield put(blogListSlice.actions.setBlogTotalCount(totalCount));
            yield put(blogListSlice.actions.setBlogList(data));
            yield put(loaderSlice.actions.hide());
        }
    } catch (err: any) {
        yield put(loaderSlice.actions.hide());
        toast.error(err.message);
    }
}

function* deleteBlogItem(action: PayloadAction<{ id: string }>): any {
    const values: Object = action.payload;
    try {
        yield put(loaderSlice.actions.show());
        const blog = yield call(deleteBlogService, values);
        const { data: { deleteBlog = {} } = {} } = blog;
        const { error, message } = deleteBlog;
        toast.success('Blog Deleted Successfully');
        const blogListParams = yield select((state) => {
            return state.blogSlice.blogListParams;
        });
        try {
            yield put(loaderSlice.actions.show());
            const prop = yield call(getbloglist, blogListParams);
            const { data: { getBlogList = {} } = {} } = prop;
            const { error, message, data, totalCount } = getBlogList;
            if (error) {
                yield put(loaderSlice.actions.hide());
                toast.error(message);
            } else {
                yield put(blogListSlice.actions.setBlogTotalCount(totalCount));
                yield put(blogListSlice.actions.setBlogList(data));
                yield put(loaderSlice.actions.hide());
            }
        } catch (err: any) {
            yield put(loaderSlice.actions.hide());
            toast.error(err.message);
        }
        if (error) {
            yield put(loaderSlice.actions.hide());
            toast.error(message);
        } else {
            yield put(loaderSlice.actions.hide());
        }
    } catch (err: any) {
        yield put(loaderSlice.actions.hide());
        toast.error(err.message);
    }
}

function* getBlogByID(action: PayloadAction<{ id: string }>): any {
    const values: Object = action.payload;
    try {
        yield put(loaderSlice.actions.show());
        const blogData = yield call(getblogbyid, values);
        const { data: { getBlogById = {} } = {} } = blogData;
        // eslint-disable-next-line no-unused-vars
        const { error, message, data } = getBlogById;
        if (error) {
            yield put(loaderSlice.actions.hide());
            toast.error(message);
        } else {
            yield put(blogListSlice.actions.setBlogByID(data));
            yield put(loaderSlice.actions.hide());
        }
    } catch (err: any) {
        yield put(loaderSlice.actions.hide());
        toast.error(err.message);
    }
}

function* updateBlog(action: any): any {
    const values: Object = action.payload;
    const tempImageArray: any = [];
    const imageDataUpdate: any = [];
    const {
        imageArray,
        id,
        deletedImageArray,
        tagUpdateImagesArray,
        relatedProperty
    } = action.payload;
    // const relatedPropertyArray = relatedProperty?.map((elm:any) => elm.id);

    imageArray.map((i: any) => {
        i.image && !i.small && tempImageArray.push(i);
        i.small && i?.blobUrl &&
            imageDataUpdate.push({
                blobUrl: i?.blobUrl,
                title: i.title,
            });
    });

    const deleteImageArray: any = [];
    deletedImageArray &&
        deletedImageArray.length &&
        deletedImageArray.map((i: any) => {
            i?.blobUrl && deleteImageArray.push(i?.blobUrl);
        });

    try {
        yield put(loaderSlice.actions.show());
        const blogData = yield call(updateblog, {
            ...values,
            deleteImageArray,
            tagUpdateImagesArray,
            imageDataUpdate,
            relatedProperty: relatedProperty,
        });
        const { data: { editBlog = {} } = {} } = blogData;
        // eslint-disable-next-line no-unused-vars
        const { error, message, data } = editBlog;
        if (error) {
            yield put(loaderSlice.actions.hide());
            toast.error(message);
        } else {
            yield call(createPreSignedUrl, {
                imageArray: tempImageArray,
                blog_id: id,
            });

            yield put(loaderSlice.actions.hide());
            toast.success('Blog update successful');
            navigator.history.replace('/blogs');
        }
    } catch (err: any) {
        yield put(loaderSlice.actions.hide());
        toast.error(err.message);
    }
}


function* uploadBlogImage(action: any): any {
    const values = action.payload;
    try {
        yield put(loaderSlice.actions.show());

        if (values?.imageArray[0]?.blobUrl != '') {
            const link = yield call(CreateContentImageUrl, values);
            yield put(blogListSlice.actions.setPreSignedUrl({ data: { link: link.medium } }));
            yield put(loaderSlice.actions.hide());
        }

    } catch (err: any) {
        yield put(loaderSlice.actions.hide());
        toast.error(err.message);
    }
}

function* CreateContentImageUrl(action: any): any {
    const values: any[] = action.imageArray;
    const imageName = values.map(item => item.name);
    try {
        if (imageName.length) {
            const blogData = yield call(fileUpload, {
                imageNames: imageName,
                folderName: 'propertyImages',
            });
            const { data: { propertyImagePreSignedUrl = {} } = {} } = blogData;
            const { error, message, url } = propertyImagePreSignedUrl;
            if (error) {
                toast.error(message);
            } else {
                const parsedURL = JSON.parse(url);
                for (const [key, value] of Object.entries(parsedURL)) {
                    const currentObject = values.filter((obj: any) => obj.name === key);
                    if (currentObject.length) {
                        const payload = { link: value, file: currentObject[0].image };
                        const setImageUpload = yield call(setImageUploadUsing, payload);
                        if (setImageUpload.status === 200) {
                            const small = `${process.env.REACT_APP_AWS_RESIZE_IMAGE_URL}property-image-100x100/${key}`;
                            const medium = `${process.env.REACT_APP_AWS_RESIZE_IMAGE_URL}property-image-500x500/${key}`;
                            const large = `${process.env.REACT_APP_AWS_RESIZE_IMAGE_URL}property-image-1000x800/${key}`;
                            return { small, medium, large }
                        } else {
                            toast.error('Some images are failed to upload. please revalidate it.');
                            console.log('some Image Upload Error');
                        }
                    } else {
                        toast.error('we cannot find such image name. please revalidate it.');
                        console.log('we cannot find such image name');
                    }
                }
            }
        }
    } catch (err: any) {
        toast.error(err.message);
    }
}

function* createPreSignedUrl(action: any): any {
    const values: any[] = action.imageArray;
    const gifImage = values.filter(item => item?.image?.type == 'image/gif');
    const jpgImage = values.filter(item => item?.image?.type == 'image/png' || item?.image?.type == 'image/jpeg');
    const imageName = jpgImage.map(item => item.name);
    const gifName = gifImage.map(item => item.name);

    try {
        if (gifName.length) {
            const blogData = yield call(fileUpload, {
                imageNames: gifName,
                folderName: 'blogGifs',
            });
            const { data: { propertyImagePreSignedUrl = {} } = {} } = blogData;
            const { error, message, url } = propertyImagePreSignedUrl;
            if (error) {
                toast.error(message);
            } else {
                const parsedURL = JSON.parse(url);
                for (const [key, value] of Object.entries(parsedURL)) {
                    const currentObject = values.filter((obj: any) => obj.name === key);
                    if (currentObject.length) {
                        const payload = { link: value, file: currentObject[0].image };
                        const setImageUpload = yield call(setImageUploadUsing, payload);
                        if (setImageUpload.status === 200) {
                            const small = `https://propertyok-prod-images.s3.ap-south-1.amazonaws.com/originalImages/blogGifs/${key}`;
                            const medium = `https://propertyok-prod-images.s3.ap-south-1.amazonaws.com/originalImages/blogGifs/${key}`;
                            const large = `https://propertyok-prod-images.s3.ap-south-1.amazonaws.com/originalImages/blogGifs/${key}`;
                            const type = 'blog';
                            const description = currentObject[0].description
                                ? currentObject[0].description
                                : '';
                            const title = currentObject[0].title
                                ? currentObject[0].title
                                : '';
                            const content_id = currentObject[0].content_id
                                ? currentObject[0].content_id
                                : '';
                            const { blog_id } = action;
                            const { tags } = currentObject[0];
                            yield call(imageUploadBlog, {
                                small,
                                medium,
                                large,
                                type,
                                tags,
                                blog_id,
                                position: content_id,
                                description,
                                title,
                            }
                            );
                        } else {
                            toast.error('Some images are failed to upload. please revalidate it.');
                            console.log('some Image Upload Error');
                        }
                    } else {
                        toast.error('we cannot find such image name. please revalidate it.');
                        console.log('we cannot find such image name');
                    }
                }
            }
        }
        if (imageName.length) {
            const blogData = yield call(fileUpload, {
                imageNames: imageName,
                folderName: 'propertyImages',
            });
            const { data: { propertyImagePreSignedUrl = {} } = {} } = blogData;
            const { error, message, url } = propertyImagePreSignedUrl;
            if (error) {
                toast.error(message);
            } else {
                const parsedURL = JSON.parse(url);
                for (const [key, value] of Object.entries(parsedURL)) {
                    const currentObject = values.filter((obj: any) => obj.name === key);
                    if (currentObject.length) {
                        const payload = { link: value, file: currentObject[0].image };
                        const setImageUpload = yield call(setImageUploadUsing, payload);
                        if (setImageUpload.status === 200) {
                            const small = `${process.env.REACT_APP_AWS_RESIZE_IMAGE_URL}property-image-100x100/${key}`;
                            const medium = `${process.env.REACT_APP_AWS_RESIZE_IMAGE_URL}property-image-500x500/${key}`;
                            const large = `${process.env.REACT_APP_AWS_RESIZE_IMAGE_URL}property-image-1000x800/${key}`;
                            const type = 'blog';
                            const description = currentObject[0].description
                                ? currentObject[0].description
                                : '';
                            const title = currentObject[0].title
                                ? currentObject[0].title
                                : '';
                            const content_id = currentObject[0].content_id
                                ? currentObject[0].content_id
                                : '';
                            const { blog_id } = action;
                            const { tags } = currentObject[0];
                            yield call(imageUploadBlog, {
                                small,
                                medium,
                                large,
                                type,
                                tags,
                                blog_id,
                                position: content_id,
                                description,
                                title,
                            }
                            );
                        } else {
                            toast.error('Some images are failed to upload. please revalidate it.');
                            console.log('some Image Upload Error');
                        }
                    } else {
                        toast.error('we cannot find such image name. please revalidate it.');
                        console.log('we cannot find such image name');
                    }
                }
            }
        }
    } catch (err: any) {
        toast.error(err.message);
    }
}

function* statusChange(action: any): any {
    const values: Object = action.payload;

    try {
        yield put(loaderSlice.actions.show());
        const statusChange = yield call(statusChangeService, values);
        const { data: { blogStatusChange = {} } = {} } = statusChange;
        // eslint-disable-next-line no-unused-vars
        const { error, message, data = {} } = blogStatusChange;

        if (error) {
            yield put(loaderSlice.actions.hide());
            toast.error(message);
        } else {
            yield put(loaderSlice.actions.hide());
            toast.success('Blog Is Published');
            navigator.history.replace('/blogs');
        }
    } catch (err: any) {
        yield put(loaderSlice.actions.hide());
        toast.error(err.message);
    }
}

export default function* propertyList() {
    yield takeLatest(CREATE_BLOG, createblog);
    yield takeLatest(CREATE_BLOG_IMAGE_URL, createPreSignedUrl);
    yield takeLatest(GET_BLOGS, getblogList);
    yield takeLatest(DELETE_BLOG, deleteBlogItem);
    yield takeLatest(GET_BLOG_BY_ID, getBlogByID);
    yield takeLatest(EDIT_BLOG, updateBlog);
    yield takeLatest(UPLOAD_BLOG_IMAGE, uploadBlogImage);
    // yield takeLatest(GET_STATELIST, getStateList);
    // yield takeLatest(GET_DISTRICTLIST, getDistrictList);
    // yield takeLatest(GET_CITYLIST, getCityList);
    yield takeLatest(STATUS_CHANGE, statusChange);
}