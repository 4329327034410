import { gql } from '@apollo/client';

export const CREATEPRESIGNURL_MUTATION = gql`
    mutation propertyImagePreSignedUrl($imageNames:[String]!,$folderName:String!) {
        propertyImagePreSignedUrl(imageNames:$imageNames,folderName:$folderName){
            url
        }
    }
`;

export const CREATE_AMENITY_MUTATION = gql`
    mutation createAmenity($amenityName: String!) {
        createAmenity(amenityName: $amenityName) {
            error
            message
            data {
                id
            }
        }
    }
`;

export const UPDATE_AMENITY_MUTATION = gql`
    mutation updateAmenity($amenityName: String!, $amenityId: String!) {
        updateAmenity(amenityName: $amenityName, amenityId: $amenityId) {
            error
            message
            data {
                id
            }
        }
    }
`;

export const CREATEIMAGE_MUTATION = gql`
    mutation createImage($amenities_id:String $type:String! $small:String! $large:String! $medium:String! $tags:[String]) {
        createImage(amenities_id:$amenities_id,type:$type,small:$small,large:$large,medium:$medium,tags:$tags){
            error
            message
            data{
                small
            }
      }
    }   
`;

export const UPDATEIMAGE_MUTATION = gql`
    mutation updateImage($amenities_id:String! $tagUpdateImagesArray:[tagUpdateObject]) {
        updateImage(amenities_id:$amenities_id,tagUpdateImagesArray:tagUpdateImagesArray){
            error
            message
            data{
                doc_id
            }
      }
    }   
`;

export const GET_ALL_AMENITIES_QUERY = gql`
    query getAllAmenity($search:String){
        getAllAmenity(search:$search){
            error
            message
            data{
                id
                amenityName
                image
            }
        }
    }
`;

export const DELETE_AMENITY_MUTATION = gql`
    mutation deleteAmenity($id: String!) {
        deleteAmenity(id: $id) {
            error
            message
            data {
                id
            }
        }
    }
`;

export const CREATE_ADDITIONALROOMS_MUTATION = gql`
    mutation createAdditionalRooms($name: String!) {
        createAdditionalRooms(name: $name) {
            error
            message
            data{
                id
                name
            }
        }
    }
`;

export const GET_ADDITIONALROOMS_QUERY = gql`
    query getAllAdditionalRooms {
        getAllAdditionalRooms {
            error
            message
            data{
                name
                id
            }
        }
    }
`;

export const DELETE_ADDITIONALROOMS_MUTATION = gql`
    mutation deleteAdditionalRooms($id: String!) {
        deleteAdditionalRooms(id: $id) {
            error
            message
            data{
                id
                name
            }
        }
    }
`;
