import { call, getContext } from 'redux-saga/effects';
import { GET_GOOGLE_LOCATION_ADDRESS_PARTS_LIST, GET_GOOGLE_LOCATION_ADDRESS_PARTS_DETAIL } from '../queries/location.query';
import { CREATE_GOOGLE_LOCATION_ADDRESS_PARTS, UPDATE_GOOGLE_LOCATION_ADDRESS_PARTS, DELETE_GOOGLE_LOCATION_ADDRESS_PARTS } from '../mutation/location.mutation';

export function* fetchGoogleLocationAddressPartsListService(payload: any): any {
  const client = yield getContext('client');
  const res = yield call(client.query, {
    query: GET_GOOGLE_LOCATION_ADDRESS_PARTS_LIST,
    fetchPolicy: 'no-cache',
    variables: { pagination: { page: payload.page, pageSize: payload.limit }, search: payload.search, searchType: payload.searchType },
  });
  return res.data.listGoogleLocationAddressParts;
}

export function* fetchGoogleLocationAddressPartsDetailService(payload: any): any {
  const client = yield getContext('client');
  const res = yield call(client.query, {
    query: GET_GOOGLE_LOCATION_ADDRESS_PARTS_DETAIL,
    fetchPolicy: 'no-cache',
    variables: { id: payload },
  });
  return res.data.getGoogleLocationAddressParts;
}

export function* createGoogleLocationAddressPartsService(payload: any): any {
  const client = yield getContext('client');
  const res = yield call(client.mutate, {
    mutation: CREATE_GOOGLE_LOCATION_ADDRESS_PARTS,
    fetchPolicy: 'no-cache',
    variables: { input: payload.input },
  });
  return res.data.createGoogleLocationAddressParts;
}

export function* updateGoogleLocationAddressPartsService(payload: any): any {
  const client = yield getContext('client');
  const res = yield call(client.mutate, {
    mutation: UPDATE_GOOGLE_LOCATION_ADDRESS_PARTS,
    fetchPolicy: 'no-cache',
    variables: { id: payload.id, input: payload.input },
  });
  return res.data.updateGoogleLocationAddressParts;
}

export function* deleteGoogleLocationAddressPartsService(payload: any): any {
  const client = yield getContext('client');
  const res = yield call(client.mutate, {
    mutation: DELETE_GOOGLE_LOCATION_ADDRESS_PARTS,
    fetchPolicy: 'no-cache',
    variables: { id: payload.id },
  });
  return res.data.deleteGoogleLocationAddressParts;
}
