/* eslint-disable import/prefer-default-export */
export const CREATE_BLOG = 'blog_list/createBlog';
export const CREATE_BLOG_IMAGE_URL = 'blog_list/createPreSignedUrl';
export const GET_BLOGS = 'blog_list/getBlogList';
export const DELETE_BLOG = 'blog_list/deleteBlogItem';
export const GET_BLOG_BY_ID = 'blog_list/getBlogByID';
export const EDIT_BLOG = 'blog_list/updateBlog';
export const UPLOAD_BLOG_IMAGE = 'blog_list/uploadBlogImageContent';
export const SET_PRESIGNED_URL = 'blog_list/setPreSignedUrl';
// export const CREATE_AMENITIES = 'property_list/createAmenities';
// export const GET_STATELIST = 'property_list/getStateList';
// export const GET_DISTRICTLIST = 'property_list/getDistrictList';
// export const GET_CITYLIST = 'property_list/getCityList';
export const STATUS_CHANGE = 'blog_list/statusChange';