import { gql } from '@apollo/client';

export const CREATE_BLOG_MUTATION = gql`
  mutation createBlog(
    $title: String!
    $slug: String!
    $metaDataDescription: String
    $relatedProperty: [String]
    $content: [mdContentObj]
  ) {
    createBlog(
      title: $title
      slug: $slug
      metaData: $metaDataDescription
      relatedProperty: $relatedProperty
      mdContent: $content
    ) {
      error
      data {
        blog {
          title
          id
        }
      }
      message
    }
  }
`;

export const DELETE_BLOG_MUTATION = gql`
  mutation deleteBlog($id: String!) {
    deleteBlog(id: $id) {
      error
      message
      data
    }
  }
`;

export const EDIT_BLOG_MUTATION = gql`
  mutation editBlog(
    $id: String!
    $title: String!
    $slug: String!
    $metaDataDescription: String
    $content: [mdContentObj]
    $imageDataUpdate: [imageInput]
    $deleteImageArray: [String]
    $relatedProperty: [String]
  ) {
    editBlog(
      id: $id
      title: $title
      slug: $slug
      metaData: $metaDataDescription
      mdContent: $content
      imageDataUpdate: $imageDataUpdate
      deleteImageArray: $deleteImageArray
      relatedProperty: $relatedProperty
    ) {
      error
      data {
        blog {
          id
        }
      }
      message
    }
  }
`;

export const CREATEIMAGE_MUTATION = gql`
    mutation createImage($blog_id:String $type:String! $small:String! $large:String! $medium:String! $tags:[String] $position: String $title: String) {
        createImage(blog_id:$blog_id,type:$type,small:$small,large:$large,medium:$medium,tags:$tags, position: $position, title: $title){
            error
            message
            data{
                small
            }
      }
    }   
`;

export const UPDATEIMAGE_MUTATION = gql`
    mutation updateImage($blog_id:String! $tagUpdateImagesArray:[tagUpdateObject]) {
        updateImage(blog_id:$amenities_id,tagUpdateImagesArray:tagUpdateImagesArray){
            error
            message
            data{
                doc_id
            }
      }
    }   
`;

export const STATUS_CHANGE_MUTATE = gql`
  mutation blogStatusChange($id: String, $status: String, $rejectionMessage: String) {
    blogStatusChange(id: $id, status: $status, rejectionMessage: $rejectionMessage) {
      error
      message
      data {
        blog {
          id
        }
      }
    }
  }
`;
