import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { string } from 'prop-types';

type InitialState = {
  propertyLists: [],
  propertyListParams: { limit: number, search: string, sort: string, order: string, page: number, status:string[] },
  totalCount: number,
  projectById: {},
  presignedUrl: {},
  brochurePresignedUrl: {}
  stateList: [],
  districtList: [],
  cityList: [],
  placeDetails: [],
}

export const getpropertyList = (state: InitialState, action: PayloadAction<{ limit: number, search: string, sort: string, order: string, page: number, status: string[] }>) => {
  if (action.payload === undefined) {
    state.propertyListParams.search = '';
    state.propertyListParams.order = 'ASC';
    state.propertyListParams.limit = 10;
    state.propertyListParams.sort = 'email';
  } else {
    state.propertyListParams.limit = action.payload.limit;
    state.propertyListParams.search = action.payload.search;
    state.propertyListParams.sort = action.payload.sort;
    state.propertyListParams.order = action.payload.order;
    state.propertyListParams.page = action.payload.page;
    state.propertyListParams.status = action.payload.status;
  }
};
export const setpropertyList = (state: any, action: any) => {
  // eslint-disable-next-line no-param-reassign
  state.propertyLists = action.payload;
};

export const setPropertyTotalCount = (state: any, action: any) => {
  // eslint-disable-next-line no-param-reassign
  state.totalCount = action.payload;
};

export const createproperty = (state: InitialState, action: any) => {
};

export const deletePropertyItem = (state: InitialState, action: PayloadAction<{ id: string }>) => {
};

export const getProjectByID = (state: InitialState, action: PayloadAction<{ id: string }>) => {
};

export const setProjectByID = (state: InitialState, action: any) => {
  state.projectById = action.payload;
};
export const createPreSignedUrl = (state: InitialState, action: any) => {
};

export const setPreSignedUrl = (state: InitialState, action: any) => {
  state.presignedUrl = action.payload;
};

export const createBrochurePreSignedUrl = (state: InitialState, action: any) => {
};

export const setBrochurePreSignedUrl = (state: InitialState, action: any) => {
  state.brochurePresignedUrl = action.payload;
};

export const updateProject = (state: InitialState, action:any) => {
};

export const getStateList = () => {
};

export const setStateList = (state: any, action: PayloadAction<{ id: string }>) => {
  state.stateList = action.payload;
};

export const getDistrictList = (state: any, action: PayloadAction<{ parent_id: number }>) => {
};

export const setDistrictList = (state: any, action: PayloadAction<{ id: string }>) => {
  state.districtList = action.payload;
};

export const getCityList = (state: any, action: PayloadAction<{ parent_id: number }>) => {
};

export const setCityList = (state: any, action: PayloadAction<{ id: string }>) => {
  state.cityList = action.payload;
};

export const getGoogleLocationListByKeyword= (state: any, action: PayloadAction<{ keyword: string }>) => {
};

export const setGoogleLocationList = (state: any, action: PayloadAction<{ id: string }>) => {
  state.googleLocationsList = action.payload;
};

export const getPlaceDetailsById= (state: any, action: PayloadAction<{ keyword: string }>) => {
};

export const setPlaceDetails = (state: any, action: PayloadAction<{ id: string }>) => {
  state.placeDetails = action.payload;
};

export const statusChange = (state: InitialState, action: any) => {
};

const initialState: InitialState = {
  propertyLists: [],
  propertyListParams: {
    search: '',
    order: 'DESC',
    limit: 10,
    page: 1,
    sort: 'updatedAt',
    status: ['Approved']
  },
  projectById: {},
  totalCount: 0,
  presignedUrl: {},
  brochurePresignedUrl: {},
  stateList: [],
  districtList: [],
  cityList: [],
  placeDetails: []
};

const propertyListSlice = createSlice({
  name: 'property_list',
  initialState,
  reducers: {
    getpropertyList,
    setpropertyList,
    createproperty,
    deletePropertyItem,
    getProjectByID,
    setProjectByID,
    updateProject,
    setPropertyTotalCount,
    createPreSignedUrl,
    setPreSignedUrl,
    createBrochurePreSignedUrl,
    setBrochurePreSignedUrl,
    getStateList,
    setStateList,
    getDistrictList,
    setDistrictList,
    getCityList,
    setCityList,
    statusChange,
    getGoogleLocationListByKeyword,
    setGoogleLocationList,
    getPlaceDetailsById,
    setPlaceDetails
  },
});

export default propertyListSlice;
