import { call, put, takeLatest } from 'redux-saga/effects';
import loaderSlice from "../slices/loader.slice";
import { toast } from "react-toastify";

import {
  getGoogleLocationAddressPartsList,
  setGoogleLocationAddressPartsList,
  getGoogleLocationAddressPartsDetail,
  setGoogleLocationAddressPartsDetail,
  createGoogleLocationAddressParts,
  updateGoogleLocationAddressParts,
  deleteGoogleLocationAddressParts,
  updateGoogleLocationAddressPartsSuccess
} from '../slices/location.slice';
import {
  fetchGoogleLocationAddressPartsDetailService,
  fetchGoogleLocationAddressPartsListService,
  createGoogleLocationAddressPartsService,
  updateGoogleLocationAddressPartsService,
  deleteGoogleLocationAddressPartsService,
} from '../graphql/services/location.service';

import { GET_GOOGLE_LOCATION_ADDRESS_PARTS_LIST, UPDATE_GOOGLE_LOCATION_ADDRESS_PARTS, CREATE_GOOGLE_LOCATION_ADDRESS_PARTS,DELETE_GOOGLE_LOCATION_ADDRESS_PARTS, GET_GOOGLE_LOCATION_ADDRESS_PARTS_DETAIL, SET_GOOGLE_LOCATION_ADDRESS_PARTS_DETAIL, SET_GOOGLE_LOCATION_ADDRESS_PARTS_LIST } from '../actions/locations.action';

function* handleGetGoogleLocationAddressPartsList(action: ReturnType<typeof getGoogleLocationAddressPartsList>):  Generator<any, void, any> {
  try {    
    yield put(loaderSlice.actions.show());
    const response = yield call(fetchGoogleLocationAddressPartsListService, action.payload);
    yield put(setGoogleLocationAddressPartsList(response));
  } catch (error) {
    console.error(error);
    yield put(loaderSlice.actions.hide());
  }finally{
    yield put(loaderSlice.actions.hide());  
  }
}

function* handleGetGoogleLocationAddressPartsDetail(action: ReturnType<typeof getGoogleLocationAddressPartsDetail>): Generator<any, void, any> {
  try {    
    const response = yield call(fetchGoogleLocationAddressPartsDetailService, action.payload);
    yield put(setGoogleLocationAddressPartsDetail(response));
  } catch (error) {
    console.error(error);
  }
}

function* handleCreateGoogleLocationAddressParts(action: ReturnType<typeof createGoogleLocationAddressParts>) {
  try {
    yield call(createGoogleLocationAddressPartsService, action.payload);
    yield put(getGoogleLocationAddressPartsList());
  } catch (error) {
    console.error(error);
  }
}

function* handleUpdateGoogleLocationAddressParts(action: ReturnType<typeof updateGoogleLocationAddressParts>) {
  try {
    yield put(loaderSlice.actions.show());
    yield call(updateGoogleLocationAddressPartsService, action.payload);
    yield put(
      updateGoogleLocationAddressPartsSuccess({
        id: action.payload.id,
        input: action.payload.input,
      })
    );
    toast.success("Location data updated successfully.");
  } catch (error) {
    toast.error("Failed to update location data.");
    console.error(error);
  } finally {
    yield put(loaderSlice.actions.hide());
  }
}

function* handleDeleteGoogleLocationAddressParts(action: ReturnType<typeof deleteGoogleLocationAddressParts>) {
  try {
    yield call(deleteGoogleLocationAddressPartsService, action.payload);
    yield put(getGoogleLocationAddressPartsList());
  } catch (error) {
    console.error(error);
  }
}

export default function* googleLocationAddressPartsSagas() {
  yield takeLatest(GET_GOOGLE_LOCATION_ADDRESS_PARTS_LIST, handleGetGoogleLocationAddressPartsList);
  yield takeLatest(GET_GOOGLE_LOCATION_ADDRESS_PARTS_DETAIL, handleGetGoogleLocationAddressPartsDetail);
  yield takeLatest(CREATE_GOOGLE_LOCATION_ADDRESS_PARTS, handleCreateGoogleLocationAddressParts);
  yield takeLatest(UPDATE_GOOGLE_LOCATION_ADDRESS_PARTS, handleUpdateGoogleLocationAddressParts);
  yield takeLatest(DELETE_GOOGLE_LOCATION_ADDRESS_PARTS, handleDeleteGoogleLocationAddressParts);
}