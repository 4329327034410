import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InitialState = {
  featuredLocationList: [],
  featuredLocationParams: { limit: number, search: string, sort: string, order: string, page: number},
}

const initialState: InitialState = {
    featuredLocationList: [],
    featuredLocationParams: {
        search: '',
        order: 'DESC',
        limit: 100,
        page: 1,
        sort: 'updatedAt',
    },
};


export const createFeaturedLocation = (state: InitialState, action: any) => {
};


export const getfeaturedLocationList = (state: InitialState, action: PayloadAction<{ limit: number, search: string, sort: string, order: string, page: number }|undefined>) => {
  if (action.payload === undefined) {
    state.featuredLocationParams.search = '';
    state.featuredLocationParams.order = 'DESC';
    state.featuredLocationParams.limit = 100;
    state.featuredLocationParams.sort = 'updatedAt';
    state.featuredLocationParams.page = 1;

  } else {
    state.featuredLocationParams.limit = action.payload.limit;
    state.featuredLocationParams.search = action.payload.search;
    state.featuredLocationParams.sort = action.payload.sort;
    state.featuredLocationParams.order = action.payload.order;
    state.featuredLocationParams.page = action.payload.page;
  }
};

export const setfeaturedLocation = (state: any, action: any) => {
  // eslint-disable-next-line no-param-reassign
  state.featuredLocationList = action.payload;
};



export const deleteFeaturedLocationById = (state: InitialState, action: PayloadAction<{ id: string }>) => {
};

export const getFeaturedLocationById = (state: InitialState, action: PayloadAction<{ id: string }>) => {
};


export const updateFeaturedLocation= (state: InitialState, action:any) => {
};



const featuredLocationSlice = createSlice({
  name: 'featured_location_list',
  initialState,
  reducers: {
    createFeaturedLocation,
    getfeaturedLocationList,
    setfeaturedLocation,
    deleteFeaturedLocationById,
    getFeaturedLocationById,
    updateFeaturedLocation
  },
});

export default featuredLocationSlice;