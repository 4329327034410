import { call, getContext } from 'redux-saga/effects';
import axios from 'axios';
import { CREATE_DEVELOPER_MUTATION, DELETEDEVELOPER_MUTATION, EDIT_DEVELOPER_MUTATION } from '../mutation/developer.mutation';
import { DEVELOPERLIST_QUERY, DEVELOPERBYID_QUERY } from '../queries/developer.query';
import { CREATEPRESIGNURL_MUTATION } from '../mutation/property.mutation';

export function* createDeveloperService(payload: any) {
  const client = yield getContext('client');
  const user = yield call(client.mutate, {
    mutation: CREATE_DEVELOPER_MUTATION,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return user;
}

export function* getdeveloperlist(payload: any) {
  const client = yield getContext('client');
  const user = yield call(client.query, {
    query: DEVELOPERLIST_QUERY,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return user;
}

export function* deleteDeveloper(payload: any) {
  const client = yield getContext('client');
  const user = yield call(client.query, {
    query: DELETEDEVELOPER_MUTATION,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return user;
}

export function* getdeveloperbyid(payload: any) {
  const client = yield getContext('client');
  const user = yield call(client.query, {
    query: DEVELOPERBYID_QUERY,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return user;
}

export function* updateDeveloper(payload: any) {
  const client = yield getContext('client');
  const user = yield call(client.mutate, {
    mutation: EDIT_DEVELOPER_MUTATION,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return user;
}

export function* fileUpload(payload: any) {
  const client = yield getContext('client');
  const user = yield call(client.mutate, {
    mutation: CREATEPRESIGNURL_MUTATION,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return user;
}

export const setImageUploadUsing = async (payload: any) => {
  const awsS3ImageUpload = await axios.put(payload.link, payload.file);
  return awsS3ImageUpload;
};
