import { gql } from '@apollo/client';

export const FEATURED_LOCATION_LIST = gql`
  query FeaturedLocationList($limit: Int, $search: String, $sort: String, $order: String, $page: Int) {
    featuredLocationList(limit: $limit, search: $search, sort: $sort, order: $order, page: $page) {
      error
      locationData {
        id
        location_name
        location_id
        isActive
        createdAt
        updatedAt
      }
      message
    }
  }
`;

export const FEATURED_LOCATION_BY_ID = gql`
  query FeaturedLocationById($id: ID!) {
    featuredLocationById(id: $id) {
      error
      data {
        id
        location_name
        location_id
        isActive
        createdAt
        updatedAt
      }
      message
    }
  }
`;

