import { gql } from "@apollo/client";

// Mutations
export const CREATE_FEATURED_LOCATION = gql`
  mutation CreateFeaturedLocation($location_name: String!, $location_id: ID!) {
    createFeaturedLocation(location_name: $location_name, location_id: $location_id) {
      error
      message
    }
  }
`;

export const EDIT_FEATURED_LOCATION = gql`
  mutation EditFeaturedLocation($id: ID!, $location_name: String, $location_id: ID) {
    editFeaturedLocation(id: $id, location_name: $location_name, location_id: $location_id) {
      error
      message
    }
  }
`;

export const DELETE_FEATURED_LOCATION = gql`
  mutation DeleteFeaturedLocation($id: ID!) {
    deleteFeaturedLocation(id: $id) {
      error
      message
    }
  }
`;