import React from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
//import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store/rootReducer';

export const Spinner = () => {
  const visible = useSelector((state: RootState) => state.loader.visible);
  return (
    <>
      {visible
        ? (
          <div className="fixed h-full w-full flex items-center justify-center z-50 bg-black bg-opacity-25">
            <PuffLoader
              css={`
              display: block;
              margin: 0 auto;
              border-color: '#0E278F';
            `}
              size={55}
              color="#0E278F"
              loading
            />
          </div>
        )
        : null}
    </>
  );
};

export default Spinner;
