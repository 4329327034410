import {
  put, takeLatest, call, select,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { PayloadAction } from '@reduxjs/toolkit';
import usersSlice from '../slices/users.slice';
import loaderSlice from '../slices/loader.slice';
import {
  createUserService, getuserlist, userDeleteService, getuserbyid, edituser, fileUpload, setImageUploadUsing, imageLinkUpload
} from '../graphql/services/users.service';
import {
  CREATE_USER, GET_USERS, DELETE_USER, GET_USER_BY_ID, EDIT_USER,
} from '../actions/users.actions';
import { navigator } from '../../common/routes/navigator';

function* createUser(action: PayloadAction<{ username: string; password: string }>) {
  const values: Object = action.payload;
  try {
    yield put(loaderSlice.actions.show());
    const createnewuser = yield call(createUserService, values);
    const { data: { register = {} } = {} } = createnewuser;
    // eslint-disable-next-line no-unused-vars
    const { error, message, data = {} } = register;
    if (error) {
      yield put(loaderSlice.actions.hide());
      toast.error(message);
    } else {
      const { user } = data;
      yield call(createPreSignedUrl, { ...values, user_id: user.id });
      yield put(loaderSlice.actions.hide());
      toast.success('Account created successfully');
      navigator.history.replace('/user');
    }
  } catch (err:any) {
    yield put(loaderSlice.actions.hide());
    toast.error(err.message);
  }
}

function* getuserList(action: PayloadAction<{ limit: number, search: string, sort: string, order: string, page: number }>) {
  const values: Object = action.payload;
  try {
    yield put(loaderSlice.actions.show());
    const user = yield call(getuserlist, values);
    const { data: { getUserList = {} } = {} } = user;
    // eslint-disable-next-line no-unused-vars
    const {
      error, message, data, totalCount,
    } = getUserList;
    if (error) {
      yield put(loaderSlice.actions.hide());
      toast.error(message);
    } else {
      yield put(usersSlice.actions.setuserTotalCount(totalCount));
      yield put(usersSlice.actions.setUsersList(data));
      yield put(loaderSlice.actions.hide());
    }
  } catch (err:any) {
    yield put(loaderSlice.actions.hide());
    toast.error(err.message);
  }
}

function* userDelete(action: PayloadAction<{ id: string }>) {
  const values: Object = action.payload;
  try {
    yield put(loaderSlice.actions.show());
    const deleteUserConst = yield call(userDeleteService, values);
    const { data: { deleteUser = {} } = {} } = deleteUserConst;
    const { error, message } = deleteUser;
    toast.success(message);
    const userListParams = yield select((state) => state.userListSlice.userListParams);
    try {
      yield put(loaderSlice.actions.show());
      const user = yield call(getuserlist, userListParams);
      const { data: { getUserList = {} } = {} } = user;
      // eslint-disable-next-line no-unused-vars
      const { error, message, data, totalCount,
      } = getUserList;
      if (error) {
        yield put(loaderSlice.actions.hide());
        toast.error(message);
      } else {
        yield put(usersSlice.actions.setuserTotalCount(totalCount));
        yield put(usersSlice.actions.setUsersList(data));
        yield put(loaderSlice.actions.hide());
      }
    } catch (err:any) {
      yield put(loaderSlice.actions.hide());
      toast.error(err.message);
    }
    if (error) {
      yield put(loaderSlice.actions.hide());
      toast.error(message);
    } else {
      yield put(loaderSlice.actions.hide());
    }
  } catch (err:any) {
    yield put(loaderSlice.actions.hide());
    toast.error(err.message);
  }
}

function* getUserByID(action: PayloadAction<{ id: string }>) {
  const values: Object = action.payload;
  try {
    yield put(loaderSlice.actions.show());
    const user = yield call(getuserbyid, values);
    const { data: { getUser = {} } = {} } = user;
    // eslint-disable-next-line no-unused-vars
    const { error, message, data } = getUser;
    if (error) {
      yield put(loaderSlice.actions.hide());
      toast.error(message);
    } else {
      yield put(usersSlice.actions.setUserByID(data));
      yield put(loaderSlice.actions.hide());
    }
  } catch (err:any) {
    yield put(loaderSlice.actions.hide());
    toast.error(err.message);
  }
}

function* EditUser(action: PayloadAction<{ id: string, firstName: string, lastName: string, email: string, phoneNumber: string, role: string, imageArray: any, deletedImageArray: any }>) {
  const values: Object = action.payload;
  const { imageArray, deletedImageArray, id } = action.payload;
  try {
    yield put(loaderSlice.actions.show());
    const user = yield call(edituser, { ...values, deletedImageArray });
    const { data: { editUser = {} } = {} } = user;
    // eslint-disable-next-line no-unused-vars
    const { error, message, data } = editUser;
    if (error) {
      yield put(loaderSlice.actions.hide());
      toast.error(message);
    } else {
      yield call(createPreSignedUrl, { imageArray, user_id: id });
      yield put(loaderSlice.actions.hide());
      toast.success('User information update successful');
      navigator.history.replace('/user');
    }
  } catch (err:any) {
    yield put(loaderSlice.actions.hide());
    toast.error(err.message);
  }
}
function* createPreSignedUrl(action: any) {
  const values: any = action.imageArray;
  const imageName: string[] = [];
  values.length && values.map((item: any) => {
    imageName.push(item.name);
  });
  try {
    if (imageName.length) {
      const propertyData = yield call(fileUpload, { imageNames: imageName, folderName: 'Profile' });
      const { data: { propertyImagePreSignedUrl = {} } = {} } = propertyData;
      const { error, message, url } = propertyImagePreSignedUrl;
      if (error) {
        toast.error(message);
      } else {
        const parsedURL = JSON.parse(url);
        for (const [key, value] of Object.entries(parsedURL)) {
          const currentObject = values.filter((obj: any) => obj.name === key);
          if (currentObject.length) {
            const payload = { link: value, file: currentObject[0].image };
            const setImageUpload = yield call(setImageUploadUsing, payload);
            if (setImageUpload.status === 200) {
              const small = `${process.env.REACT_APP_RESIZE_IMAGE_URL}Profile/profile-image-100x100/${key}`;
              const medium = `${process.env.REACT_APP_RESIZE_IMAGE_URL}Profile/profile-image-500x500/${key}`;
              const large = `${process.env.REACT_APP_RESIZE_IMAGE_URL}Profile/profile-image-1000x800/${key}`;
              const type = 'profile';
              const description = '';
              const title = '';
              const { user_id } = action;
              const tags: any = [];
              yield call(imageLinkUpload, {
                small, medium, large, type, tags, user_id, description, title,
              });
            } else {
              console.log('some Image Upload Error');
            }
          } else {
            console.log('we cannot find such image name');
          }
        }
      }
    }
  } catch (err:any) {
    toast.error(err.message);
  }
}


export default function* userList() {
  yield takeLatest(CREATE_USER, createUser);
  yield takeLatest(GET_USERS, getuserList);
  yield takeLatest(DELETE_USER, userDelete);
  yield takeLatest(GET_USER_BY_ID, getUserByID);
  yield takeLatest(EDIT_USER, EditUser);
}
