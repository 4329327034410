import { call, put, takeLatest } from "redux-saga/effects";
import loaderSlice from "../slices/loader.slice";
import { toast } from "react-toastify";
import { CREATE_TESTIMONIAL, DELETE_TESTIMONIAL_BY_ID, GET_TESTIMONIAL_BY_ID, GET_TESTIMONIAL_LIST, UPDATE_TESTIMONIAL } from "../actions/testimonial.action";
import { addTestimonialService, deleteTestimonialService, getTestimonialByIdService, getTestimonialListService, updateTestimonialService } from "../graphql/services/testimonial.service";
import TestimonialSlice from "../slices/testimonial.slice";
import { fileUpload, setImageUploadUsing } from "../graphql/services/property.service";
import { navigator } from "../../common/routes/navigator";

const keywordListParams = {
    search: '',
    order: 'DESC',
    limit: 100,
    page: 1,
    sort: 'updatedAt',
}

function* createTestimonial(action: any): any {
  const values = action.payload;

  if (values?.profile_img?.blobUrl) {
    const res = yield call(testimonialImageUpload, values.profile_img);

    if (res?.status) {
      values.profile_img = res?.imageUrl;
    } else {
      values.profile_img = '';
    }
  } else {
    values.profile_img = '';
  }

  try {
    yield put(loaderSlice.actions.show());

    const res = yield call(addTestimonialService, { ...values });

    const error = res?.data?.testimonialList?.error;
    const message = res?.data?.testimonialList?.message;

    if (error) {
      yield put(loaderSlice.actions.hide());
      toast.error(message);
    } else {
      yield put(loaderSlice.actions.hide());
      toast.success('Testimonial has been successfully created');
      navigator.history.replace('/testimonials');
     // yield put(TestimonialSlice.actions.getTestimonialList(keywordListParams));
    }
  } catch (err: any) {
    yield put(loaderSlice.actions.hide());
    toast.error(err.message);
  }
}

function* getTestimonialList(action: any): any {
    const values = action.payload;
    try {
        yield put(loaderSlice.actions.show());

        const res = yield call(getTestimonialListService, { ...values});

        const error = res?.data?.testimonialList?.error;
        const message = res?.data?.testimonialList?.message;
        const data = res?.data?.testimonialList?.listData;
        const totalCount = res?.data?.testimonialList?.totalCount;

        if (error) {
            yield put(loaderSlice.actions.hide());
            toast.error(message);
        } else {
            yield put(loaderSlice.actions.hide());
            yield put(TestimonialSlice.actions.setTestimonial(data));
            yield put(TestimonialSlice.actions.setTotalCount(totalCount));
        }
    } catch (err: any) {
        yield put(loaderSlice.actions.hide());
        toast.error(err.message);
    }
}


function* testimonialImageUpload(imageObj: any): any {
  const { blobUrl, image, name, title } = imageObj;

  try {

    if (blobUrl != '') {

      const res = yield call(fileUpload, {
        imageNames: name,
        folderName: 'testimonialImages',
      });

      const { data: { propertyImagePreSignedUrl = {} } = {} } = res;
      const { error, message, url } = propertyImagePreSignedUrl;

      if (error) {
        toast.error(message);
      } else {

        const parsedURL = JSON.parse(url);
        const uploadUrl = parsedURL[name];

        if (uploadUrl) {

          const payload = { link: uploadUrl, file: image };
          const setImageUpload = yield call(setImageUploadUsing, payload);
          if (setImageUpload.status === 200) {
            const imageUrl = `https://propertyok-prod-images.s3.ap-south-1.amazonaws.com/originalImages/testimonialImages/${name}`;
            return { status: true, imageUrl };
          }
        } else {
          return { status: false, url: null };
        }
      }
    } else {
      console.log('Image not found');
    }
  } catch (err: any) {
    toast.error(err.message);
  }
}


function* getTestimonialById(action: any): any {
    const values = action.payload;
    try {
        yield put(loaderSlice.actions.show());

        const res = yield call(getTestimonialByIdService, { ...values});

        const error = res?.data?.testimonialById?.error;
        const message = res?.data?.testimonialById?.message;
        const data = res?.data?.testimonialById?.data;

        if (error) {
            yield put(loaderSlice.actions.hide());
            toast.error(message);
        }else{
            yield put(loaderSlice.actions.hide());
            yield put(TestimonialSlice.actions.setSingleTestimonialData(data));
        }

    } catch (err: any) {
        yield put(loaderSlice.actions.hide());
        toast.error(err.message);
    }
}
function* deleteTestimonialById(action: any): any {
    const values = action.payload;
    try {
        yield put(loaderSlice.actions.show());

        const res = yield call(deleteTestimonialService, { ...values});

        const error = res?.data?.testimonialList?.error;
        const message = res?.data?.testimonialList?.message;


        if (error) {
            yield put(loaderSlice.actions.hide());
            toast.error(message);
        } else {
            yield put(loaderSlice.actions.hide());
            yield put(TestimonialSlice.actions.getTestimonialList(keywordListParams));
        }
    } catch (err: any) {
        yield put(loaderSlice.actions.hide());
        toast.error(err.message);
    }
}


function* updateTestimonial(action: any): any {
    const values = action.payload;

    if (values?.profile_img?.image) {
      const res = yield call(testimonialImageUpload, values.profile_img);
  
      if (res?.status) {
        values.profile_img = res?.imageUrl;
      } else {
        values.profile_img = '';
      }
    } else {
      values.profile_img = values.profile_img?.blobUrl || '';
    }
   
    try {
        yield put(loaderSlice.actions.show());

        const res = yield call(updateTestimonialService, { ...values});

        const error = res?.data?.testimonialList?.error;
        const message = res?.data?.testimonialList?.message;

        if (error) {
            yield put(loaderSlice.actions.hide());
            toast.error(message);
        } else {
            yield put(loaderSlice.actions.hide());
            toast.success('Testimonial has been successfully updated');
            navigator.history.replace('/testimonials');
        }
    } catch (err: any) {
        yield put(loaderSlice.actions.hide());
        toast.error(err.message);
    }
}


export default function* TestimonialSaga() {
    yield takeLatest(CREATE_TESTIMONIAL, createTestimonial);
    yield takeLatest(GET_TESTIMONIAL_LIST, getTestimonialList)
    yield takeLatest(DELETE_TESTIMONIAL_BY_ID, deleteTestimonialById);
    yield takeLatest(UPDATE_TESTIMONIAL, updateTestimonial)
    yield takeLatest(GET_TESTIMONIAL_BY_ID, getTestimonialById)
}