/* eslint-disable import/prefer-default-export */
import { call, getContext } from 'redux-saga/effects';
import { SIGNIN_QUERY, REFRESHTOKEN_QUERY } from '../queries/auth.query';

export function* login(payload: any) {
  const client = yield getContext('client');
  const user = yield call(client.query, {
    query: SIGNIN_QUERY,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return user;
}

export function* refreshOAuthTokensService(payload: any) {
  const client = yield getContext('client');
  const user = yield call(client.query, {
    query: REFRESHTOKEN_QUERY,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return user;
}