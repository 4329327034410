import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const createAmenities = (state: InitialState, action: PayloadAction<{ amenityName: string }>) => {
};

export const updateAmenities = (state: InitialState, action: PayloadAction<{ amenityName: string, amenityId: string }>) => {
};

const createPreSignedUrl = (state: InitialState, action: any) => {
};

const DeleteAmenity = (state: any, action: PayloadAction<{ id: string }>) => {
};

const setPreSignedUrl = (state: InitialState, action: any) => {
  state.presignedUrl = action.payload;
};

const GetAllAmenities = (state: InitialState, action: PayloadAction<{ search: string}>)  => {
};

const setamenityList = (state: any, action: PayloadAction<{ id: string }>) => {
  // eslint-disable-next-line no-param-reassign
  state.amenities = action.payload;
};

const CreateAdditionalRooms = (state: any, action: PayloadAction<{ name: string }>) => {
};

const GetAdditionalRooms = () => {
};

const setAdditionalRoomList = (state: any, action: PayloadAction<{ id: string }>) => {
  state.additionalRoom = action.payload;
};

const DeleteAdditionalRoom = (state: any, action: PayloadAction<{ id: string }>) => {
};

type InitialState = {
    presignedUrl: {},
    getallAmenity: {},
    settingsLists: [],
    amenities: [],
    additionalRoom: [],
}

const initialState: InitialState = {
  presignedUrl: {},
  getallAmenity: {},
  settingsLists: [],
  amenities: [],
  additionalRoom: [],
};

const settingsSlice = createSlice({
  name: 'settings_list',
  initialState,
  reducers: {
    createAmenities,
    updateAmenities,
    createPreSignedUrl,
    setPreSignedUrl,
    GetAllAmenities,
    setamenityList,
    DeleteAmenity,
    CreateAdditionalRooms,
    GetAdditionalRooms,
    setAdditionalRoomList,
    DeleteAdditionalRoom,
  },
});

export default settingsSlice;
