/* eslint-disable import/no-unresolved */
import { combineReducers } from 'redux';
import loaderSlice from '../slices/loader.slice';
import userListSlice from '../slices/users.slice';
import propertySlice from '../slices/property.slice';
import blogSlice from '../slices/blog.slice';
import authSlice from '../slices/auth.slice';
import developerSlice from '../slices/developer.slice';
import settingsSlice from '../slices/settings.slice';
import keywordSlice from '../slices/trendingKeywords.slice';
import featuredLocationSlice from '../slices/featuredLocation.slice';
import TestimonialSlice from '../slices/testimonial.slice';
import locationsSlice from '../slices/location.slice';

const rootReducer = combineReducers({
  loader: loaderSlice.reducer,
  userListSlice: userListSlice.reducer,
  propertySlice: propertySlice.reducer,
  blogSlice: blogSlice.reducer,
  authSlice: authSlice.reducer,
  developerSlice: developerSlice.reducer,
  settingsSlice: settingsSlice.reducer,
  keywordSlice: keywordSlice.reducer,
  featuredLocationSlice: featuredLocationSlice.reducer,
  testimonialSlice: TestimonialSlice.reducer,
  locationSlice: locationsSlice.reducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
