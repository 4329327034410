import { gql } from '@apollo/client';

export const CREATE_USER_MUTATION = gql`
    mutation createUser($firstName: String!, $lastName: String!, $email: String!, $phoneNumber: String!, $role: String!,$currentAddress:String,$permanantAddress:String,$gender:String,$dateOfBirth:String) {
        register(firstName: $firstName, lastName: $lastName, email: $email, phoneNumber: $phoneNumber, role: $role,currentAddress:$currentAddress,permanantAddress:$permanantAddress,gender:$gender,dateOfBirth:$dateOfBirth){
            error
            data{
                user{
                    id
                    email
                    firstName
                    lastName
                    phoneNumber
                    role
              }
            accessToken
            refreshToken
            }
            message
        }
    }   
`;

export const DELETEUSER_MUTATION = gql`
    mutation userDelete($id:String!) {
        deleteUser(id:$id){
            error
            message
            data
      }
    }   
`;

export const EDITUSER_MUTATION = gql`
    mutation editUser($id:String!, $firstName: String!, $lastName: String!, $email: String!, $phoneNumber: String!, $role: String!,$currentAddress:String,$permanantAddress:String,$gender:String,$dateOfBirth:String,$deletedImageArray:[userImageDelete]) {
        editUser(id: $id, firstName: $firstName, lastName: $lastName, email: $email, phoneNumber: $phoneNumber, role: $role,currentAddress:$currentAddress,permanantAddress:$permanantAddress,gender:$gender,dateOfBirth:$dateOfBirth,deletedImageArray:$deletedImageArray){
            error
            data{
                user{
                    firstName
                }
            }
            message    
        }
      
    }   
`;

export const CREATEPRESIGNURL_MUTATION = gql`
    mutation propertyImagePreSignedUrl($imageNames:[String]!,$folderName:String!) {
        propertyImagePreSignedUrl(imageNames:$imageNames,folderName:$folderName){
            url
        }
    }   
`;
export const CREATEIMAGE_MUTATION = gql`
    mutation createImage($description:String $user_id:String! $type:String! $small:String! $large:String! $medium:String! $tags:[String] $title:String) {
        createImage(description:$description,user_id:$user_id,type:$type,small:$small,large:$large,medium:$medium,tags:$tags, title:$title){
            error
            message
            data{
                small
            }
      }
    }   
`;

export const UPDATEIMAGE_MUTATION = gql`
    mutation updateImage($description:String $user_id:String! $tagUpdateImagesArray:[tagUpdateObject]) {
        updateImage(description:$description,user_id:$user_id,tagUpdateImagesArray:tagUpdateImagesArray){
            error
            message
            data{
                small
            }
      }
    }   
`;

