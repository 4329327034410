import { call, getContext } from 'redux-saga/effects';
import { ADD_TRENDING_KEYWORD_MUTATION, DELETE_TRENDING_KEYWORD_MUTATION, UPDATE_TRENDING_KEYWORD_MUTATION } from '../mutation/trendingKeyword.mutation';
import { TRENDING_KEYWORD_BY_ID_QUERY, TRENDING_KEYWORD_LIST_QUERY } from '../queries/trendingKeyword.query';

export function* addTrendingKeywordService(payload: any): any {
  const client = yield getContext('client');
  const res  = yield call(client.mutate, {
    mutation: ADD_TRENDING_KEYWORD_MUTATION,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return res;
}

export function* updateTrendingKeywordService(payload: any): any {
    const client = yield getContext('client');
    const res  = yield call(client.mutate, {
      mutation: UPDATE_TRENDING_KEYWORD_MUTATION,
      fetchPolicy: 'no-cache',
      variables: { ...payload },
    });
    return res;
}

export function* deleteTrendingKeywordService(payload: any): any {
    const client = yield getContext('client');
    const res  = yield call(client.mutate, {
      mutation: DELETE_TRENDING_KEYWORD_MUTATION,
      fetchPolicy: 'no-cache',
      variables: { ...payload },
    });
    return res;
}


export function* getTrendingKeywordListService(payload: any): any {
  const client = yield getContext('client');
  const res = yield call(client.query, {
    query: TRENDING_KEYWORD_LIST_QUERY,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return res;
}

export function* getTrendingKeywordByIdService(payload: any): any {
    const client = yield getContext('client');
    const res = yield call(client.query, {
      query: TRENDING_KEYWORD_BY_ID_QUERY,
      fetchPolicy: 'no-cache',
      variables: { ...payload },
    });
    return res;
}