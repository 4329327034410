import { put, takeLatest, call, select,all} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { PayloadAction } from '@reduxjs/toolkit';
import propertyListSlice from '../slices/property.slice';
import loaderSlice from '../slices/loader.slice';
import { navigator } from '../../common/routes/navigator';

import {
  createpropertyService,
  getpropertylist,
  deletePropertyService,
  updateproject,
  getprojectbyid,
  fileUpload,
  setImageUploadUsing,
  imageLinkUpload,
  getStateListService,
  getDistrictListService,
  getGoogleLocationListByKeywordService,
  getCityListService,
  statusChangeService,
  getPlaceDetailsByIdService,
  brochurePresignedUrlGenerate,
  uploadBrochureUsingPreSignedUrl
} from '../graphql/services/property.service';
// import { imageLinkUpload } from '../graphql/services/image.service';
import {
  CREATE_PROPERTY,
  GET_PROPETIES,
  DELETE_PROPERTY,
  GET_PROJECT_BY_ID,
  EDIT_PROJECT,
  CREATE_PROPERTY_IMAGE_URL,
  GET_STATELIST,
  GET_DISTRICTLIST,
  GET_CITYLIST,
  STATUS_CHANGE,
  GET_GOOGLE_LOCATION_LIST_BY_KEYWORD,
  GET_PLACE_DETAILS_BY_ID,
  CREATE_BROCHURE_PRESIGNED_URL
} from '../actions/property.actions';

function* createproperty(action: any) : any {
  const values: any = action.payload;

  try {
    yield put(loaderSlice.actions.show());

    const signedUrlResponse = yield call(createBrochurePreSignedUrl, values.brochure);

    if(signedUrlResponse){
      values.brochureLink = signedUrlResponse ;
    }
    
    const crtProperty = yield call(createpropertyService, values);
    const { data: { createProperty = {} } = {} } = crtProperty;
    // eslint-disable-next-line no-unused-vars
    const { error, message, data = {} } = createProperty;

    if (error) {
      yield put(loaderSlice.actions.hide());
      toast.error(message);
    } else {
      const { property } = data;
      let imageArray =  values?.imageArray?.filter((item: { isDeleted: any; }) => !item?.isDeleted);
      imageArray = imageArray?.concat(values?.planImageArray)
      // imageArray.map((item: any, index: any) => {
      //   item.position = index;
      // });
      yield call(createPreSignedUrl, { imageArray: imageArray, property_id: property.id });
      yield put(loaderSlice.actions.hide());
      toast.success('Project created successful');
      navigator.history.replace('/projects');
    }
  } catch (error : any) {
    yield put(loaderSlice.actions.hide());
    toast.error(error.message);
  }
}

function* getpropertyList(
  action: PayloadAction<{
    limit: number;
    search: string;
    sort: string;
    order: string;
    page: number;
    status: string[];
  }>,
) : any {
  const values: Object = action.payload;
  try {
    yield put(loaderSlice.actions.show());
    const property = yield call(getpropertylist, values);
    const { data: { getPropertyList = {} } = {} } = property;
    const { error, message, data, totalCount } = getPropertyList;
    if (error) {
      yield put(loaderSlice.actions.hide());
      toast.error(message);
    } else {
      yield put(propertyListSlice.actions.setPropertyTotalCount(totalCount));
      yield put(propertyListSlice.actions.setpropertyList(data));
      yield put(loaderSlice.actions.hide());
    }
  } catch (error : any) {
    yield put(loaderSlice.actions.hide());
    toast.error(error.message);
  }
}

function* deletePropertyItem(action: PayloadAction<{ id: string }>) : any {
  const values: Object = action.payload;
  try {
    yield put(loaderSlice.actions.show());
    const property = yield call(deletePropertyService, values);
    const { data: { deleteProperty = {} } = {} } = property;
    const { error, message } = deleteProperty;
    toast.success('Property Deleted Successfully');
    const propertyListParams = yield select((state) => {
      return state.propertySlice.propertyListParams;
    });
    try {
      yield put(loaderSlice.actions.show());
      const prop = yield call(getpropertylist, propertyListParams);
      const { data: { getPropertyList = {} } = {} } = prop;
      const { error, message, data, totalCount } = getPropertyList;
      if (error) {
        yield put(loaderSlice.actions.hide());
        toast.error(message);
      } else {
        yield put(propertyListSlice.actions.setPropertyTotalCount(totalCount));
        yield put(propertyListSlice.actions.setpropertyList(data));
        yield put(loaderSlice.actions.hide());
      }
    } catch (error : any) {
      yield put(loaderSlice.actions.hide());
      toast.error(error.message);
    }
    if (error) {
      yield put(loaderSlice.actions.hide());
      toast.error(message);
    } else {
      yield put(loaderSlice.actions.hide());
    }
  } catch (error : any) {
    yield put(loaderSlice.actions.hide());
    toast.error(error.message);
  }
}

function* getProjectByID(action: PayloadAction<{ id: string }>) : any {
  const values: Object = action.payload;
  try {
    yield put(loaderSlice.actions.show());
    const propertyData = yield call(getprojectbyid, values);
    const { data: { getProperty = {} } = {} } = propertyData;
    // eslint-disable-next-line no-unused-vars
    const { error, message, data } = getProperty;
    if (error) {
      yield put(loaderSlice.actions.hide());
      toast.error(message);
    } else {
      yield put(propertyListSlice.actions.setProjectByID(data));
      yield put(loaderSlice.actions.hide());
    }
  } catch (error : any) {
    yield put(loaderSlice.actions.hide());
    toast.error(error.message);
  }
}

function* updateProject(action: any) : any{
  const values: any = action.payload;

  let {
    imageArray,
    id,
    tagUpdateImagesArray,
    deletedPlanImageArray,
    planImageArray
  } = action.payload;


  let deleteImageArray: any = [];
  let newImages: any = [];
  let planImageUpdate: any = [];
  let updatedImageArray: any =[]
 
  imageArray.map((item: any, index: number) => {

    const isDeleted = item?.isDeleted;
    const imageSize = item?.image?.size;

    //const position = index.toString();
  
    if ((isDeleted && !imageSize)) {
      deleteImageArray.push(item?.image);
    } else if (!isDeleted && !imageSize) {
      updatedImageArray.push({
        blobUrl: item?.blobUrl || item?.medium,
        tags: item?.tags,
        title: item?.title,
        position: item?.position,
        home_featured: item?.home_featured,
        search_featured: item?.search_featured,
        social_share_featured: item?.social_share_featured,
        details_page_featured: item?.details_page_featured,
        alt_text: item?.alt_text,
      });
    } else if (!isDeleted && imageSize) {
      newImages.push({...item});
    }
  });
  
  
  // To delete existing image, it's medium url needs to be passed. 
  // That array should contains urls, otherwise invalid graphql error will be thrown

  const resultArray : any[]= [];
  deletedPlanImageArray.map((item: any) => {
      if(item.blobUrl){
        resultArray.push(item.blobUrl);
      }
  })

  deleteImageArray = deleteImageArray.concat(resultArray);
  planImageArray.map((item:any )=> {
     
    if(item?.image?.size){
      newImages.push(item)
    }else{
      updatedImageArray.push({
        blobUrl: item?.blobUrl || item?.medium,
        tags: item?.tags,
        title: item?.title,
        description: item?.description,
        alt_text: item?.alt_text,
      });
    }
  })



  
  try {
    yield put(loaderSlice.actions.show());

    const signedUrlResponse = yield call(createBrochurePreSignedUrl, values?.brochure);

    if(signedUrlResponse){
      values.brochureLink = signedUrlResponse ;
    }

    const propertyData = yield call(updateproject, {
      ...values,
      deleteImageArray,
      tagUpdateImagesArray : updatedImageArray,
      planImageUpdate,
    });
    const { data: { editProperty = {} } = {} } = propertyData;
    // eslint-disable-next-line no-unused-vars
    const { error, message, data } = editProperty;
    if (error) {
      yield put(loaderSlice.actions.hide());
      toast.error(message);
    } else {
      yield call(createPreSignedUrl, {
        imageArray: newImages,
        property_id: id,
      });

      yield put(loaderSlice.actions.hide());
      toast.success('Project update successful');
      navigator.history.replace('/projects');
    }
  } catch (error : any) {
    yield put(loaderSlice.actions.hide());
    toast.error(error.message);
   }
}

function* createBrochurePreSignedUrl(action: any) : any{

  if(action?.brochureFile){

    const {brochureName, brochureFile} = action;
    const responseData : any = yield call(brochurePresignedUrlGenerate, {
      brochureName: brochureName,
      folderName: 'brochures',
    });

    const { data: { propertyBrochurePreSignedUrl = {} } = {} } = responseData;
    const { error, message, url } = propertyBrochurePreSignedUrl;

    const parsedURL = JSON.parse(url)
    const signedUrl =  Object.values(parsedURL)[0]

    const payload = { link: signedUrl, file: brochureFile};
    const setImageUpload = yield call(uploadBrochureUsingPreSignedUrl, payload);

    const resourceUrl = `${process.env.REACT_APP_PDF_FILE_BASE_URL}/${brochureName}`

    return resourceUrl;

  }else {
    return null
  }
}

function* createPreSignedUrl(action: any) : any {

  const imageArray = action.imageArray

  console.log('createPreSignedUrl',imageArray)

  const imageName: string[] = [];
  imageArray.length &&
    imageArray.map((item: any) => {
      imageName.push(item.name);
    });
  try {
    if (imageName.length) {
      const propertyData = yield call(fileUpload, {
        imageNames: imageName,
        folderName: 'propertyImages',
      });
      
      const { data: { propertyImagePreSignedUrl = {} } = {} } = propertyData;
      const { error, message, url } = propertyImagePreSignedUrl;
      if (error) {
        toast.error(message);
      } else {
        const parsedURL = JSON.parse(url);
        
        for (const [key, value] of Object.entries(parsedURL)) {
          const currentObject = imageArray.filter((obj: any) => obj.name === key);
          if (currentObject.length) {
            const payload = { link: value, file: currentObject[0].image };
            const setImageUpload = yield call(setImageUploadUsing, payload);
            
            if (setImageUpload.status === 200) {
              const small = `${process.env.REACT_APP_AWS_RESIZE_IMAGE_URL}property-image-100x100/${key}`;
              const medium = `${process.env.REACT_APP_AWS_RESIZE_IMAGE_URL}property-image-500x500/${key}`;
              const large = `${process.env.REACT_APP_AWS_RESIZE_IMAGE_URL}property-image-1000x800/${key}`;
              const type = 'property';
              const description = currentObject[0].description
                ? currentObject[0].description
                : '';
              const title = currentObject[0].title
                ? currentObject[0].title
                : '';
              const alt_text = currentObject[0].alt_text ? currentObject[0].alt_text : '';
              const { property_id } = action;
              const { tags } = currentObject[0];
              const { home_featured, search_featured, social_share_featured, details_page_featured, position } = currentObject[0];
              yield call(imageLinkUpload, {
                small,
                medium,
                large,
                type,
                tags,
                property_id,
                description,
                title,
                home_featured,
                search_featured,
                social_share_featured,
                details_page_featured,
                position: position?.toString(),
                alt_text
              });
            } else {
              toast.error('Some images are failed to upload. please revalidate it.');
              console.log('some Image Upload Error');
            }
          } else {
            toast.error('we cannot find such image name. please revalidate it.');
            console.log('we cannot find such image name');
          }
        }
      }
    }
  } catch (error : any) {
    toast.error(error.message);
  }
}

function* getGoogleLocationListByKeyword(action: PayloadAction<{keyword: string}>) : any {

  const values: Object = action.payload;

  try {
    yield put(loaderSlice.actions.show());
    const locationData = yield call(getGoogleLocationListByKeywordService, values);
    const { data: { getGoogleLocationByKeyword = {} } = {} } = locationData;
    const { error, message, data } = getGoogleLocationByKeyword;
    if (error) {
      yield put(loaderSlice.actions.hide());
      toast.error(message);
    } else {
      yield put(propertyListSlice.actions.setGoogleLocationList(data));
      yield put(loaderSlice.actions.hide());
    }
  } catch (error : any) {
    yield put(loaderSlice.actions.hide());
    toast.error(error.message);
  }
}


function* getPlaceDetailsById(action: any) : any{
  const values: Object = action.payload;

  try {
    yield put(loaderSlice.actions.show());
    const locationData = yield call(getPlaceDetailsByIdService, values);
    const { data: { getPlaceDetailsByKeyword = {} } = {} } = locationData;
    const { error, message, data } = getPlaceDetailsByKeyword;
    if (error) {
      yield put(loaderSlice.actions.hide());
      toast.error(message);
    } else {
      yield put(propertyListSlice.actions.setPlaceDetails(data));
      yield put(loaderSlice.actions.hide());

    }
  } catch (error : any) {
    yield put(loaderSlice.actions.hide());
    toast.error(error.message);
  }
}

function* getStateList() : any {
  try {
    yield put(loaderSlice.actions.show());
    const stateListConst = yield call(getStateListService);
    const { data: { getAllState = {} } = {} } = stateListConst;
    const { error, message, data } = getAllState;
    if (error) {
      yield put(loaderSlice.actions.hide());
      toast.error(message);
    } else {
      yield put(propertyListSlice.actions.setStateList(data));
      yield put(loaderSlice.actions.hide());
    }
  } catch (error:any) {
    yield put(loaderSlice.actions.hide());
    toast.error(error.message);
  }
}

function* getDistrictList(action: PayloadAction<{ parent_id: number }>):any {
  const values: Object = action.payload;
  try {
    yield put(loaderSlice.actions.show());
    const districtConst = yield call(getDistrictListService, values);
    const { data: { getDistricts = {} } = {} } = districtConst;
    const { error, message, data } = getDistricts;
    if (error) {
      yield put(loaderSlice.actions.hide());
      toast.error(message);
    } else {
      yield put(propertyListSlice.actions.setDistrictList(data));
      yield put(loaderSlice.actions.hide());
    }
  } catch (error:any) {
    yield put(loaderSlice.actions.hide());
    toast.error(error.message);
  }
}

function* getCityList(action: PayloadAction<{ id: string }>):any {
  const values: Object = action.payload;
  try {
    yield put(loaderSlice.actions.show());
    const locationConst = yield call(getCityListService, values);
    const { data: { getCity = {} } = {} } = locationConst;
    const { error, message, data } = getCity;
    if (error) {
      yield put(loaderSlice.actions.hide());
      toast.error(message);
    } else {
      yield put(propertyListSlice.actions.setCityList(data));
      yield put(loaderSlice.actions.hide());
    }
  } catch (error:any) {
    yield put(loaderSlice.actions.hide());
    toast.error(error.message);
  }
}

function* statusChange(action: any):any {
  const values: Object = action.payload;

  try {
    yield put(loaderSlice.actions.show());

    const statusChange = yield call(statusChangeService, values);
    const { data: { propertyStatusChange = {} } = {} } = statusChange;
    // eslint-disable-next-line no-unused-vars
    const { error, message, data = {} } = propertyStatusChange;

    if (error) {
      yield put(loaderSlice.actions.hide());
      toast.error(message);
    } else {
      yield put(loaderSlice.actions.hide());
      toast.success('Project created successful');
      navigator.history.replace('/projects');
    }
  } catch (error:any) {
    yield put(loaderSlice.actions.hide());
    toast.error(error.message);
  }
}
export default function* propertyList() {
  yield takeLatest(CREATE_PROPERTY, createproperty);
  yield takeLatest(GET_PROPETIES, getpropertyList);
  yield takeLatest(DELETE_PROPERTY, deletePropertyItem);
  yield takeLatest(GET_PROJECT_BY_ID, getProjectByID);
  yield takeLatest(EDIT_PROJECT, updateProject);
  yield takeLatest(CREATE_PROPERTY_IMAGE_URL, createPreSignedUrl);
  yield takeLatest(GET_STATELIST, getStateList);
  yield takeLatest(GET_DISTRICTLIST, getDistrictList);
  yield takeLatest(GET_CITYLIST, getCityList);
  yield takeLatest(STATUS_CHANGE, statusChange);
  yield takeLatest(GET_GOOGLE_LOCATION_LIST_BY_KEYWORD,getGoogleLocationListByKeyword);
  yield takeLatest(GET_PLACE_DETAILS_BY_ID , getPlaceDetailsById)
  yield takeLatest(CREATE_BROCHURE_PRESIGNED_URL , createBrochurePreSignedUrl)

}
