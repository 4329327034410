import { takeEvery, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { PayloadAction } from '@reduxjs/toolkit';
import { login, refreshOAuthTokensService } from '../graphql/services/auth.service';
import {
  LOGIN_REQUEST, LOGOUT_REQUEST, REFRESHTOKEN_REQUEST,
} from '../actions/auth.actions';
import loaderSlice from '../slices/loader.slice';
import { storageEngine } from '../../common/helper';
import { navigator } from '../../common/routes/navigator';

function* signinUser(action: PayloadAction<{ username: string; password: string }>) {
  const values: Object = action.payload;
  try {
    yield put(loaderSlice.actions.show());
    const user = yield call(login, values);
    const { data: { signin = {} } = {} } = user;
    // eslint-disable-next-line no-unused-vars
    const { error, message, data = {} } = signin;
    if (error) {
      yield put(loaderSlice.actions.hide());
      toast.error(message);
    } else {
      storageEngine.set('user', data);
      yield put(loaderSlice.actions.hide());
      toast.success('Login successful');
      navigator.history.replace('/projects');
    }
  } catch (err) {
    yield put(loaderSlice.actions.hide());
    toast.error(err.message);
  }
}

function* refreshOAuthTokens(action: PayloadAction<{ refreshToken: string }>) {
  const values: Object = action.payload;
  try {
    yield put(loaderSlice.actions.show());
    const tokenData = yield call(refreshOAuthTokensService, values);
    const { data: { refreshToken = {} } = {} } = tokenData;
    // eslint-disable-next-line no-unused-vars
    const { error, message, data = {} } = refreshToken;
    if (error) {
      yield put(loaderSlice.actions.hide());
      storageEngine.unset('user');
      navigator.history.replace('/');
    } else {
      yield put(loaderSlice.actions.hide());
    }
  } catch (err) {
    yield put(loaderSlice.actions.hide());
    storageEngine.unset('user');
    navigator.history.replace('/');
    toast.error(err.message);
  }
}

function* logout() {
  try {
    storageEngine.unset('user');
    navigator.history.replace('/');
  } catch (err) {

  }
}

export default function* signIn() {
  yield takeEvery(LOGIN_REQUEST, signinUser);
  yield takeEvery(LOGOUT_REQUEST, logout);
  yield takeEvery(REFRESHTOKEN_REQUEST, refreshOAuthTokens);
}
