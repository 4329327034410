/* eslint-disable import/prefer-default-export */
export const CREATE_PROPERTY = 'property_list/createproperty';
export const GET_PROPETIES = 'property_list/getpropertyList';
export const DELETE_PROPERTY = 'property_list/deletePropertyItem';
export const GET_PROJECT_BY_ID = 'property_list/getProjectByID';
export const EDIT_PROJECT = 'property_list/updateProject';
export const CREATE_PROPERTY_IMAGE_URL = 'property_list/createPreSignedUrl';
export const CREATE_AMENITIES = 'property_list/createAmenities';
export const GET_STATELIST = 'property_list/getStateList';
export const GET_DISTRICTLIST = 'property_list/getDistrictList';
export const GET_CITYLIST = 'property_list/getCityList';
export const STATUS_CHANGE = 'property_list/statusChange';
export const GET_GOOGLE_LOCATION_LIST_BY_KEYWORD = 'property_list/getGoogleLocationListByKeyword';
export const GET_PLACE_DETAILS_BY_ID = 'property_list/getPlaceDetailsById';
export const CREATE_BROCHURE_PRESIGNED_URL = 'property_list/createBrochurePreSignedUrl';

