import { put, takeLatest, call, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { PayloadAction } from '@reduxjs/toolkit';
import developerSlice from '../slices/developer.slice';
import loaderSlice from '../slices/loader.slice';
import { navigator } from '../../common/routes/navigator';

import {
  createDeveloperService,
  getdeveloperlist,
  deleteDeveloper,
  updateDeveloper,
  getdeveloperbyid,
  setImageUploadUsing,
} from '../graphql/services/developer.service';

import { imageLinkUpload } from '../graphql/services/image.service';
import {
  CREATE_DEVELOPER,
  GET_DEVELOPERS,
  DELETE_DEVELOPER,
  GET_DEVELOPER_BY_ID,
  EDIT_DEVELOPER,
  CREATE_DEVELOPER_IMAGE_URL,
} from '../actions/developer.actions';
import { fileUpload } from '../graphql/services/property.service';

function* createDeveloper(
  action: PayloadAction<{
    name: string;
    projectsdescription: string;
    address: string;
    yearsOfExperience: number;
    totalProjects: number;
    projectsCompleted: number;
    projectsOngoing: number;
    operatedIn: string;
    imageArray: any;
  }>,
) {
  const values: Object = action.payload;
  try {
    yield put(loaderSlice.actions.show());
    const crtDeveloper = yield call(createDeveloperService, values);
    const { data: { createBuilder = {} } = {} } = crtDeveloper;
    const { error, message, data = {} } = createBuilder;

    if (error) {
      yield put(loaderSlice.actions.hide);
      toast.error(
        'Could not create developer please check your entries',
        message,
      );
    } else {
      const { id } = data;
      yield call(createPreSignedUrl, { ...values, builder_id: id });
      yield put(loaderSlice.actions.hide());
      toast.success('Developer created successful');
      navigator.history.replace('/developer');
    }
  } catch (err) {
    yield put(loaderSlice.actions.hide());
    toast.error(err.message);
  }
}

function* getdeveloperList(
  action: PayloadAction<{
    limit: number;
    search: string;
    sort: string;
    order: number;
    page: number;
  }>,
) {
  const values: Object = action.payload;
  try {
    yield put(loaderSlice.actions.show());
    const developer = yield call(getdeveloperlist, values);
    const { data: { getBuilderList = {} } = {} } = developer;
    const { error, message, data, totalCount } = getBuilderList;
    if (error) {
      yield put(loaderSlice.actions.hide());
      toast.error(message);
    } else {
      yield put(developerSlice.actions.setdeveloperList(data));
      yield put(developerSlice.actions.setDeveloperTotalCount(totalCount));
      yield put(loaderSlice.actions.hide());
    }
  } catch (err) {
    yield put(loaderSlice.actions.hide());
    toast.error(err.message);
  }
}

function* DeleteDeveloper(action: PayloadAction<{ id: string }>) {
  const values: Object = action.payload;
  try {
    yield put(loaderSlice.actions.show());
    const deleteDeveloperConst = yield call(deleteDeveloper, values);
    const { data: { deleteBuilder = {} } = {} } = deleteDeveloperConst;
    const { error, message } = deleteBuilder;
    toast.success('Property Deleted Successfully');
    const developerListParams = yield select(
      (state) => state.developerSlice.developerListParams,
    );
    try {
      yield put(loaderSlice.actions.show());
      const deve = yield call(getdeveloperlist, developerListParams);
      const { data: { getBuilderList = {} } = {} } = deve;
      const { error, message, data, totalCount } = getBuilderList;
      if (error) {
        yield put(loaderSlice.actions.hide());
        toast.error('message');
      } else {
        yield put(developerSlice.actions.setdeveloperList(data));
        yield put(developerSlice.actions.setDeveloperTotalCount(totalCount));
        yield put(loaderSlice.actions.hide());
      }
    } catch (err) {
      yield put(loaderSlice.actions.hide());
      toast.error(err.message);
    }
    if (error) {
      yield put(loaderSlice.actions.hide());
      toast.error(message);
    } else {
      yield put(loaderSlice.actions.hide());
    }
  } catch (err) {
    yield put(loaderSlice.actions.hide());
    toast.error(err.message);
  }
}

function* getDeveloperByID(action: PayloadAction<{ id: string }>) {
  const values: Object = action.payload;
  try {
    yield put(loaderSlice.actions.show());
    const developer = yield call(getdeveloperbyid, values);
    const { data: { getBuilder = {} } = {} } = developer;
    // eslint-disable-next-line no-unused-vars
    const { error, message, data } = getBuilder;
    if (error) {
      yield put(loaderSlice.actions.hide());
      toast.error(message);
    } else {
      yield put(developerSlice.actions.setDeveloperByID(data));
      yield put(loaderSlice.actions.hide());
    }
  } catch (err) {
    yield put(loaderSlice.actions.hide());
    toast.error(err.message);
  }
}

function* UpdateDeveloper(action: any) {
  const values: Object = action.payload;
  const {
    imageArray,
    id,
    deletedImageArray,
    tagUpdateImagesArray,
  } = action.payload;
  const tempImageArray: any = [];
  imageArray.map((i: any) => {
    i.image && tempImageArray.push(i);
  });
  const deleteImageArray: any = [];
  deletedImageArray &&
    deletedImageArray.length &&
    deletedImageArray.map((i: any) => {
      i.blobUrl && deleteImageArray.push(i.blobUrl);
    });
  try {
    yield put(loaderSlice.actions.show());
    const developer = yield call(updateDeveloper, {
      ...values,
      deleteImageArray,
    });
    const { data: { editBuilder = {} } = {} } = developer;
    // eslint-disable-next-line no-unused-vars
    const { error, message, data } = editBuilder;
    if (error) {
      yield put(loaderSlice.actions.hide());
      toast.error(message);
    } else {
      yield call(createPreSignedUrl, {
        imageArray: tempImageArray,
        builder_id: id,
      });
      // if (tagUpdateImagesArray && tagUpdateImagesArray.length) {
      //   yield call(imageUpdate, {doc_id:id,tagUpdateImagesArray });
      // }

      yield put(loaderSlice.actions.hide());
      toast.success('Developer update successful');
      navigator.history.replace('/developer');
    }
  } catch (err) {
    yield put(loaderSlice.actions.hide());
    toast.error(err.message);
  }
}

function* createPreSignedUrl(action: any) {
  const values: any = action.imageArray;
  const imageName: string[] = [];
  values.length &&
    values.map((item: any) => {
      imageName.push(item.name);
    });

  try {
    if (imageName.length) {
      const propertyData = yield call(fileUpload, {
        imageNames: imageName,
        folderName: 'Builder',
      });
      const { data: { propertyImagePreSignedUrl = {} } = {} } = propertyData;
      const { error, message, url } = propertyImagePreSignedUrl;
      if (error) {
        toast.error(message);
      } else {
        const parsedURL = JSON.parse(url);
        for (const [key, value] of Object.entries(parsedURL)) {
          const currentObject = values.filter((obj: any) => obj.name === key);
          if (currentObject.length) {
            const payload = { link: value, file: currentObject[0].image };
            const setImageUpload = yield call(setImageUploadUsing, payload);
            if (setImageUpload.status === 200) {
              const small = `${process.env.REACT_APP_BUILDER_RESIZE_IMAGE_URL}builder-image-100x100/${key}`;
              const medium = `${process.env.REACT_APP_BUILDER_RESIZE_IMAGE_URL}builder-image-500x500/${key}`;
              const large = `${process.env.REACT_APP_BUILDER_RESIZE_IMAGE_URL}builder-image-1000x800/${key}`;
              const type = 'builder';
              const description = 'desc description';
              const { builder_id } = action;
              const { tags } = currentObject[0];
              yield call(imageLinkUpload, {
                small,
                medium,
                large,
                type,
                tags,
                builder_id,
                description,
              });
            } else {
              console.log('some Image Upload Error');
            }
          } else {
            console.log('we cannot find such image name');
          }
        }
      }
    }
  } catch (err) {
    toast.error(err.message);
  }
}

export default function* developerSaga() {
  yield takeLatest(CREATE_DEVELOPER, createDeveloper);
  yield takeLatest(GET_DEVELOPERS, getdeveloperList);
  yield takeLatest(DELETE_DEVELOPER, DeleteDeveloper);
  yield takeLatest(GET_DEVELOPER_BY_ID, getDeveloperByID);
  yield takeLatest(EDIT_DEVELOPER, UpdateDeveloper);
  yield takeLatest(CREATE_DEVELOPER_IMAGE_URL, createPreSignedUrl);
}
