import { gql } from '@apollo/client';

export const DEVELOPERLIST_QUERY = gql`
query getBuilderList($limit:Int!,$search:String!,$sort:String!,$order:String!,$page:Int!) {
    getBuilderList(limit:$limit,search:$search,sort:$sort,order:$order,page:$page){
        error
        message
        totalCount
        data{
            id
            name
            address
            operatedIn
            projectsdescription
            yearsOfExperience
            totalProjects
            projectsCompleted
            projectsOngoing
      }
    }
}
`;

export const DEVELOPERBYID_QUERY = gql`
query getBuilder($id:String!) {
    getBuilder(id:$id){
    error
    message
    data{
        id
        name
        address
        operatedIn
        projectsdescription
        yearsOfExperience
        totalProjects
        projectsCompleted
        projectsOngoing
        image{
            small
            large
            medium
        }
    }
} 
}
`;
