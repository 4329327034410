import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InitialState = {
  keywordList: [],
  keywordListParams: { limit: number, search: string, sort: string, order: string, page: number},
}

const initialState: InitialState = {
    keywordList: [],
    keywordListParams: {
        search: '',
        order: 'DESC',
        limit: 100,
        page: 1,
        sort: 'updatedAt',
    },
};


export const createTrendingKeyword = (state: InitialState, action: any) => {
};


export const getKeywordList = (state: InitialState, action: PayloadAction<{ limit: number, search: string, sort: string, order: string, page: number }|undefined>) => {
  if (action.payload === undefined) {
    console.log('ramish-trig')
    state.keywordListParams.search = '';
    state.keywordListParams.order = 'DESC';
    state.keywordListParams.limit = 100;
    state.keywordListParams.sort = 'updatedAt';
    state.keywordListParams.page = 1;

  } else {
    state.keywordListParams.limit = action.payload.limit;
    state.keywordListParams.search = action.payload.search;
    state.keywordListParams.sort = action.payload.sort;
    state.keywordListParams.order = action.payload.order;
    state.keywordListParams.page = action.payload.page;
  }
};

export const setKeywordList = (state: any, action: any) => {
  // eslint-disable-next-line no-param-reassign
  state.keywordList = action.payload;
};



export const deleteKeywordById = (state: InitialState, action: PayloadAction<{ id: string }>) => {
};

export const getKeywordById = (state: InitialState, action: PayloadAction<{ id: string }>) => {
};


export const updateKeyword= (state: InitialState, action:any) => {
};



const keywordSlice = createSlice({
  name: 'trending_keyword_list',
  initialState,
  reducers: {
    createTrendingKeyword,
    getKeywordList,
    setKeywordList,
    deleteKeywordById,
    getKeywordById,
    updateKeyword
  },
});

export default keywordSlice;