/* eslint-disable import/prefer-default-export */
import { call, getContext } from 'redux-saga/effects';
import {
  CREATE_PROPERTY_MUTATION,
  DELETEPROPERTY_MUTATION,
  EDIT_PROJECT_MUTATION,
  CREATEPRESIGNURL_MUTATION,
  CREATEIMAGE_MUTATION,
  UPDATEIMAGE_MUTATION,
  STATUS_CHANGE_MUTATE,
  CREATE_BROCHURE_PRESIGNURL_MUTATION
} from '../mutation/property.mutation';
import {
  PROPERTYLIST_QUERY,
  PROJECTBYID_QUERY,
  GET_STATELIST_QUERY,
  GET_DISTRICT_QUERY,
  GET_CITY_QUERY,
  GET_GOOGLE_LOCATIONLIST_BY_KEYWORD_QUERY,
  GET_PLACE_DETAILS_BY_ID_QUERY
} from '../queries/property.query';
import axios from 'axios';

export function* createpropertyService(payload: any) {
  const client = yield getContext('client');
  const user = yield call(client.mutate, {
    mutation: CREATE_PROPERTY_MUTATION,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return user;
}

export function* getpropertylist(payload: any) {
  const client = yield getContext('client');
  const user = yield call(client.query, {
    query: PROPERTYLIST_QUERY,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return user;
}

export function* deletePropertyService(payload: any) {
  const client = yield getContext('client');
  const user = yield call(client.query, {
    query: DELETEPROPERTY_MUTATION,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return user;
}

export function* getprojectbyid(payload: any) {
  const client = yield getContext('client');
  const user = yield call(client.query, {
    query: PROJECTBYID_QUERY,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return user;
}

export function* updateproject(payload: any) : any{
  const client = yield getContext('client');
  const user = yield call(client.mutate, {
    mutation: EDIT_PROJECT_MUTATION,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return user;
}

export function* fileUpload(payload: any) : any {
  const client = yield getContext('client');

  const user = yield call(client.mutate, {
    mutation: CREATEPRESIGNURL_MUTATION,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return user;
}

export const setImageUploadUsing = async (payload: any) => {
  const awsS3ImageUpload = await axios.put(payload.link, payload.file);
  return awsS3ImageUpload;
};

export function* brochurePresignedUrlGenerate(payload: any) : any {
  const client = yield getContext('client');

  const data = yield call(client.mutate, {
    mutation: CREATE_BROCHURE_PRESIGNURL_MUTATION,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return data;
}

export const uploadBrochureUsingPreSignedUrl = async (payload: any) => {
  const headers = {
    'Content-Type': 'application/pdf', 
  };
  const awsS3ImageUpload = await axios.put(payload.link, payload.file, { headers });
  return awsS3ImageUpload;
};

export function* imageLinkUpload(payload: any) {
  const client = yield getContext('client');

  const crate = yield call(client.mutate, {
    mutation: CREATEIMAGE_MUTATION,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return crate;
}
export function* imageUpdate(payload: any) {
  const client = yield getContext('client');

  const crate = yield call(client.mutate, {
    mutation: UPDATEIMAGE_MUTATION,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return crate;
}

export function* getGoogleLocationListByKeywordService(payload:any) {
  const client = yield getContext('client');
  const user = yield call(client.query, {
    query: GET_GOOGLE_LOCATIONLIST_BY_KEYWORD_QUERY,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return user;
}

export function* getPlaceDetailsByIdService(payload: any) {
  const client = yield getContext('client');
  const user = yield call(client.mutate, {
    mutation: GET_PLACE_DETAILS_BY_ID_QUERY,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return user;
}


export function* getStateListService() {
  const client = yield getContext('client');
  const user = yield call(client.query, {
    query: GET_STATELIST_QUERY,
    fetchPolicy: 'no-cache',
    variables: {},
  });
  return user;
}

export function* getDistrictListService(payload: any) {
  const client = yield getContext('client');
  const user = yield call(client.query, {
    query: GET_DISTRICT_QUERY,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return user;
}

export function* getCityListService(payload: any) {
  const client = yield getContext('client');
  const user = yield call(client.query, {
    query: GET_CITY_QUERY,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return user;
}

export function* statusChangeService(payload: any) {
  const client = yield getContext('client');
  const crate = yield call(client.mutate, {
    mutation: STATUS_CHANGE_MUTATE,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return crate;
}
