/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const PROPERTYLIST_QUERY = gql`
  query getPropertyList(
    $limit: Int!
    $search: String!
    $sort: String!
    $order: String!
    $page: Int!
    $status: [String]!
  ) {
    getPropertyList(
      limit: $limit
      search: $search
      sort: $sort
      order: $order
      page: $page
      status: $status
    ) {
      error
      message
      totalCount
      data {
        id
        projectName
        builderName
        saleOrRent
        propertyType
        specifcation
        posessionYear
        descriptions
        location
        district
        status
        user {
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const PROJECTBYID_QUERY = gql`
  query getProperty($id: String!) {
    getProperty(id: $id) {
      error
      data {
        property {
          id
          projectName
          metaDataDescription
          map {
            latitude
            longitude
          }
          # aminities {
          #   src
          #   aminitiesName
          # }
          saleOrRent
          floorNo
          additionalRooms
          possessionStatus
          furnishingStatus
          propertyType
          specifcation
          facing
          posessionYear
          descriptions
          bathrooms
          numberOfParking
          towerOrblock
          unitNo
          planName
          aboutLocality
          aboutLocalityPoints
          planDescription
          state
          district
          location
          videoLink
          isPrime
          isTrending
          rejectionMessage
          areaTypePrice {
            areaTypeUpdated
            priceFrom
            priceTo
            carpetArea
            carpetAreaTo
          }
          propertyFaq {
            question
            answer
          }
          brochureLink
          isBrochureVisible
          createdAt
          updatedAt
          imageArray {
            small
            large
            tags
            medium
            title
            description
            position
            home_featured
            search_featured
            social_share_featured
            details_page_featured
            alt_text
          }
          builder {
            id
            name
            address
            yearsOfExperience
            totalProjects
            projectsCompleted
            projectsOngoing
            operatedIn
            projectsdescription
            image {
              small
              large
              medium
            }
          }
          amenitys {
            amenityId
          }
          address_description
          locationGooglePlaceId
          glocation_coordinates {
            lat
            lng
          }
          locationTags
          posessionMonth
          metaTitle
          propertyLocation
          relatedPropertyIds {
            propertyName
            id
          }
          propertySlug
          searchIndex
        }
      }
      message
    }
  }
`;

export const GET_GOOGLE_LOCATIONLIST_BY_KEYWORD_QUERY = gql`
  query getGoogleLocationByKeyword ($keyword: String!){
    getGoogleLocationByKeyword (keyword: $keyword){
      error
      message
      retrieved_from
      expireIn
      requestsCount
      data {
        placeId
        description
      }
    }
  }
`;


export const GET_PLACE_DETAILS_BY_ID_QUERY = gql`
  query getPlaceDetailsByKeyword ($keyword: String!){
    getPlaceDetailsByKeyword (keyword: $keyword){
      error
      message
      retrieved_from
      expireIn
      requestsCount
      data {
        placeId
        description
        state
        district
        location {
          lat
          lng
        }
        address_components {
          long_name
          short_name
          types
        }
      }
    }
  }
`;


export const GET_STATELIST_QUERY = gql`
  query getAllState {
    getAllState {
      error
      message
      data {
        ID
        name
      }
    }
  }
`;

export const GET_DISTRICT_QUERY = gql`
  query getDistricts($parent_id: Int!) {
    getDistricts(parent_id: $parent_id) {
      error
      message
      data {
        ID
        name
      }
    }
  }
`;

export const GET_CITY_QUERY = gql`
  query getCity($parent_id: Int!) {
    getCity(parent_id: $parent_id) {
      error
      message
      data {
        ID
        name
        cordinates {
          lat
          lng
        }
      }
    }
  }
`;
