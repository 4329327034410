import { call, getContext } from 'redux-saga/effects';
import axios from 'axios';
import { CREATEPRESIGNURL_MUTATION, CREATE_AMENITY_MUTATION, GET_ALL_AMENITIES_QUERY, CREATEIMAGE_MUTATION, DELETE_AMENITY_MUTATION, CREATE_ADDITIONALROOMS_MUTATION, GET_ADDITIONALROOMS_QUERY, DELETE_ADDITIONALROOMS_MUTATION, UPDATE_AMENITY_MUTATION } from '../mutation/settings.mutation';

export function* createamenityService(payload: any) {
  const client = yield getContext('client');
  const user = yield call(client.mutate, {
    mutation: CREATE_AMENITY_MUTATION,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return user;
}

export function* updateamenityService(payload: any) {
  const client = yield getContext('client');
  const user = yield call(client.mutate, {
    mutation: UPDATE_AMENITY_MUTATION,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return user;
}

export function* imageLinkUpload(payload: any) {
  const client = yield getContext('client');

  const crate = yield call(client.mutate, {
    mutation: CREATEIMAGE_MUTATION,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return crate;
}

export function* getAllAmenityService(payload:any) {
  const client = yield getContext('client');
  const user = yield call(client.query, {
    query: GET_ALL_AMENITIES_QUERY,
    fetchPolicy: 'no-cache',
    variables: {...payload},
  });
  return user;
}

export function* deleteAmenityService(payload: any) {
  const client = yield getContext('client');
  const user = yield call(client.mutate, {
    mutation: DELETE_AMENITY_MUTATION,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return user;
}

export function* createAdditionalRoomsService(payload: any) {
  const client = yield getContext('client');
  const user = yield call(client.mutate, {
    mutation: CREATE_ADDITIONALROOMS_MUTATION,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return user;
}

export function* getAdditionalRoomsService() {
  const client = yield getContext('client');
  const user = yield call(client.query, {
    query: GET_ADDITIONALROOMS_QUERY,
    fetchPolicy: 'no-cache',
    variables: {},
  });
  return user;
}

export function* deleteAdditionalRoomService(payload: any) {
  const client = yield getContext('client');
  const user = yield call(client.mutate, {
    mutation: DELETE_ADDITIONALROOMS_MUTATION,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return user;
}

export function* fileUpload(payload: any) {
  const client = yield getContext('client');

  const user = yield call(client.mutate, {
    mutation: CREATEPRESIGNURL_MUTATION,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return user;
}

export const amenityOnlyDangerZoon = async (payload: any) => {
  // const awsS3ImageUpload = await axios.put(payload.link, payload.file);
  // return awsS3ImageUpload;
  const awsS3ImageUpload = await axios.put(payload.link, payload.file, {
    headers: {
      'Content-Type': 'image/svg+xml'
    }
  }
  )
  return awsS3ImageUpload;
};
