import { gql } from '@apollo/client';

export const GET_GOOGLE_LOCATION_ADDRESS_PARTS_LIST = gql`
  query listGoogleLocationAddressParts($pagination: PaginationInput, $search: String, $searchType: String,) {
    listGoogleLocationAddressParts(pagination: $pagination, search: $search, searchType: $searchType) {
      error
      data {
        id
        location
        district
        types
        map {
          lat
          lng
        }
        placeId
        active
        meta_title
        meta_description
      }
      message
      totalCount
    }
  }
`;

export const GET_GOOGLE_LOCATION_ADDRESS_PARTS_DETAIL = gql`
  query getGoogleLocationAddressParts($id: ID!) {
    getGoogleLocationAddressParts(id: $id) {
      error
      data {
        id
        location
        district
        types
        map {
          lat
          lng
        }
        placeId
        active
        meta_title
        meta_description
      }
      message
    }
  }
`;
