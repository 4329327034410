import { call, getContext } from 'redux-saga/effects';
import { CREATE_TESTIMONIAL, DELETE_TESTIMONIAL, EDIT_TESTIMONIAL } from '../mutation/testimonial.mutation';
import { TESTIMONIAL_BY_ID, TESTIMONIAL_LIST } from '../queries/testimonial.query';

export function* addTestimonialService(payload: any): any {
  const client = yield getContext('client');
  const res  = yield call(client.mutate, {
    mutation: CREATE_TESTIMONIAL,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return res;
}

export function* updateTestimonialService(payload: any): any {
    const client = yield getContext('client');
    const res  = yield call(client.mutate, {
      mutation: EDIT_TESTIMONIAL,
      fetchPolicy: 'no-cache',
      variables: { ...payload },
    });
    return res;
}

export function* deleteTestimonialService(payload: any): any {
    const client = yield getContext('client');
    const res  = yield call(client.mutate, {
      mutation: DELETE_TESTIMONIAL,
      fetchPolicy: 'no-cache',
      variables: { ...payload },
    });
    return res;
}


export function* getTestimonialListService(payload: any): any {
  console.log('ramish data',payload);

  const client = yield getContext('client');
  const res = yield call(client.query, {
    query: TESTIMONIAL_LIST,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return res;
}

export function* getTestimonialByIdService(payload: any): any {
    const client = yield getContext('client');
    const res = yield call(client.query, {
      query: TESTIMONIAL_BY_ID,
      fetchPolicy: 'no-cache',
      variables: { ...payload },
    });
    return res;
}