import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const createDeveloper = (state: InitialState, action: PayloadAction<{
  name: string,
  projectsdescription: string,
  address: string,
  yearsOfExperience: number,
  totalProjects: number,
  projectsCompleted: number,
  projectsOngoing: number,
  operatedIn: string,
}>) => {
};

export const getdeveloperList = (state: InitialState, action: PayloadAction<{ limit: number, search: string, sort: string, order: string, page: number }>) => {
  if (action.payload === undefined) {
    state.developerListParams.search = '';
    state.developerListParams.order = 'ASC';
    state.developerListParams.limit = 10;
    state.developerListParams.sort = 'createdAt';
  } else {
    state.developerListParams.search = action.payload.search;
    state.developerListParams.order = action.payload.order;
    state.developerListParams.limit = action.payload.limit;
    state.developerListParams.sort = action.payload.sort;

  }
};

const setdeveloperList = (state: any, action: any) => {
  // eslint-disable-next-line no-param-reassign
  state.developerLists = action.payload;
};

const setDeveloperTotalCount = (state: any, action: any) => {
  // eslint-disable-next-line no-param-reassign
  state.totalCount = action.payload;
};

type InitialState = {
  developerLists: [],
  developerListParams: { limit: number, search: string, sort: string, order: string, page: number },
  totalCount: number,
  developerById: {},
  presignedUrl: {}
}

export const DeleteDeveloper = (state: InitialState, action: PayloadAction<{ id: string }>) => {
};

export const getDeveloperByID = (state: InitialState, action: PayloadAction<{ id: string }>) => {
};

const setDeveloperByID = (state: InitialState, action: any) => {
  state.developerById = action.payload;
};

const createPreSignedUrl = (state: InitialState, action: any) => {
};

const setPreSignedUrl = (state: InitialState, action: any) => {
  state.presignedUrl = action.payload;
};

export const UpdateDeveloper = (state: InitialState, action: any ) => {
};

const initialState: InitialState = {
  developerLists: [],
  developerListParams: { limit: 10, search: '', sort: 'name', order: 'ASC', page: 1 },
  totalCount: 100,
  developerById: {
    id: '',
    name: '',
    address: '',
    image: [],
    operatedIn: '',
    projectsdescription: '',
    yearsOfExperience: 0,
    totalProjects: 0,
    projectsCompleted: 0,
    projectsOngoing: 0,
  },
  presignedUrl: {},
};

const developerSlice = createSlice({
  name: 'developer_list',
  initialState,
  reducers: {
    createDeveloper,
    getdeveloperList,
    setdeveloperList,
    setDeveloperTotalCount,
    DeleteDeveloper,
    UpdateDeveloper,
    getDeveloperByID,
    setDeveloperByID,
    createPreSignedUrl,
    setPreSignedUrl,
  },
});

export default developerSlice;
