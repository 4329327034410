/* eslint-disable import/prefer-default-export */
import { call, getContext } from 'redux-saga/effects';
import { CREATEIMAGE_MUTATION, UPDATEIMAGE_MUTATION } from '../mutation/developer.mutation';

export function* imageLinkUpload(payload: any) {
  const client = yield getContext('client');

  const crate = yield call(client.mutate, {
    mutation: CREATEIMAGE_MUTATION,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return crate;
}
export function* imageUpdate(payload: any) {
  const client = yield getContext('client');

  const crate = yield call(client.mutate, {
    mutation: UPDATEIMAGE_MUTATION,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
  });
  return crate;
}
