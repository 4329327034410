import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InitialState = {
  testimonialList: [],
  testimonialParams: { limit: number, search: string, sort: string, order: string, page: number},
  testimonialById: { id: string, title: string, name:String, content: string, address: string, profile_img: any },
  totalCount: number,
}

const initialState: InitialState = {
    testimonialList: [],
    testimonialParams: {
        search: '',
        order: 'DESC',
        limit: 100,
        page: 1,
        sort: 'updatedAt',
    },
    
    testimonialById: {
        id: '',
        title: '',
        name:'',
        content: '',
        address: '',
        profile_img: null,
    },
    totalCount: 0,
};


export const createTestimonial = (state: InitialState, action: any) => {
};


export const setTotalCount = (state: InitialState, action: PayloadAction<number>) => {
  state.totalCount = action.payload;

}

export const getTestimonialList = (state: InitialState, action: PayloadAction<{ limit: number, search: string, sort: string, order: string, page: number }|undefined>) => {
  if (action.payload === undefined) {
    state.testimonialParams.search = '';
    state.testimonialParams.order = 'DESC';
    state.testimonialParams.limit = 100;
    state.testimonialParams.sort = 'updatedAt';
    state.testimonialParams.page = 1;

  } else {
    state.testimonialParams.limit = action.payload.limit;
    state.testimonialParams.search = action.payload.search;
    state.testimonialParams.sort = action.payload.sort;
    state.testimonialParams.order = action.payload.order;
    state.testimonialParams.page = action.payload.page;
  }
};

export const setTestimonial = (state: any, action: any) => {
  // eslint-disable-next-line no-param-reassign
  state.testimonialList = action.payload;
};

export const setSingleTestimonialData = (state: InitialState, action: any) => {
   state.testimonialById = action.payload;
}

export const deleteTestimonialById = (state: InitialState, action: PayloadAction<{ id: string }>) => {
};

export const getTestimonialById = (state: InitialState, action: PayloadAction<{ id: string }>) => {
};


export const updateTestimonial= (state: InitialState, action:any) => {
};



const TestimonialSlice = createSlice({
  name: 'testimonial_list',
  initialState,
  reducers: {
    createTestimonial,
    getTestimonialList,
    setTestimonial,
    deleteTestimonialById,
    getTestimonialById,
    updateTestimonial,
    setSingleTestimonialData,
    setTotalCount,
  },
});

export default TestimonialSlice;