import { gql } from '@apollo/client';

export const CREATE_DEVELOPER_MUTATION = gql`
    mutation createBuilder($name: String!, $address: String, $operatedIn: String, $projectsdescription: String, $yearsOfExperience: Int, $totalProjects: Int, $projectsCompleted: Int, $projectsOngoing: Int ){
        createBuilder(name: $name, address: $address, operatedIn: $operatedIn, projectsdescription: $projectsdescription, yearsOfExperience: $yearsOfExperience, totalProjects: $totalProjects, projectsCompleted: $projectsCompleted, projectsOngoing: $projectsOngoing){
            error
            message
            data{
                id
                name
                address
                operatedIn
                projectsdescription
                yearsOfExperience
                totalProjects
                projectsCompleted
                projectsOngoing
            }
        }
    }
`;

export const DELETEDEVELOPER_MUTATION = gql`
    mutation deleteBuilder($id:String!) {
        deleteBuilder(id:$id){
            error
            message
            data
      }
    }   
`;

export const EDIT_DEVELOPER_MUTATION = gql`
    mutation editBuilder(
        $id: String!, $name: String!, $address: String, $operatedIn: String, $projectsdescription: String, $yearsOfExperience: Int, $totalProjects: Int, $projectsCompleted: Int, $projectsOngoing: Int,$deleteImageArray:[String]
    ) {
        editBuilder(id:$id,name: $name, address: $address, operatedIn: $operatedIn, projectsdescription: $projectsdescription, yearsOfExperience: $yearsOfExperience, totalProjects: $totalProjects, projectsCompleted: $projectsCompleted, projectsOngoing: $projectsOngoing, deleteImageArray:$deleteImageArray
            ){
                error
                message
                data{
                    id
                    name
                    address
                    operatedIn
                    projectsdescription
                    yearsOfExperience
                    totalProjects
                    projectsCompleted
                    projectsOngoing
                }
            }   
      
    }   
`;

export const CREATEIMAGE_MUTATION = gql`
    mutation createImage($builder_id:String $type:String! $small:String! $large:String! $medium:String! $tags:[String]) {
        createImage(builder_id:$builder_id,type:$type,small:$small,large:$large,medium:$medium,tags:$tags){
            error
            message
            data{
                small
            }
      }
    }   
`;

export const UPDATEIMAGE_MUTATION = gql`
    mutation updateImage($doc_id:String! $tagUpdateImagesArray:[tagUpdateObject]) {
        updateImage(doc_id:$doc_id,tagUpdateImagesArray:tagUpdateImagesArray){
            error
            message
            data{
                doc_id
            }
      }
    }   
`;