import { call, put, takeLatest } from "redux-saga/effects";
import loaderSlice from "../slices/loader.slice";
import { toast } from "react-toastify";
import { addFeaturedLocationService, deleteFeaturedLocationService, getFeaturedLocationListService, updateFeaturedLocationService } from "../graphql/services/featuredLocation.service";
import featuredLocationSlice from "../slices/featuredLocation.slice";
import { CREATE_FEATURED_LOCATION, DELETE_FEATURED_LOCATION_BY_ID, GET_FEATURED_LOCATION_LIST, UPDATE_FEATURED_LOCATION } from "../actions/featuredLocation.action";

const keywordListParams = {
    search: '',
    order: 'DESC',
    limit: 100,
    page: 1,
    sort: 'updatedAt',
}

function* createFeaturedLocation(action: any): any {
    const values = action.payload;
    try {
        yield put(loaderSlice.actions.show());

        const res = yield call(addFeaturedLocationService, { ...values});

        const error = res?.data?.addFeaturedLocation?.error;
        const message = res?.data?.addFeaturedLocation?.message;

        if (error) {
            yield put(loaderSlice.actions.hide());
            toast.error(message);
        } else {
            yield put(loaderSlice.actions.hide());
            toast.success('Featured Location has been successfully created');
            yield put(featuredLocationSlice.actions.getfeaturedLocationList(keywordListParams));
        }
    } catch (err: any) {
        yield put(loaderSlice.actions.hide());
        toast.error(err.message);
    }
}

function* getFeaturedLocationList(action: any): any {
    const values = action.payload;
    try {
        yield put(loaderSlice.actions.show());

        const res = yield call(getFeaturedLocationListService, { ...values});

        const error = res?.data?.featuredLocationList?.error;
        const message = res?.data?.featuredLocationList?.message;
        const data = res?.data?.featuredLocationList?.locationData;


        if (error) {
            yield put(loaderSlice.actions.hide());
            toast.error(message);
        } else {
            yield put(loaderSlice.actions.hide());
            yield put(featuredLocationSlice.actions.setfeaturedLocation(data));
        }
    } catch (err: any) {
        yield put(loaderSlice.actions.hide());
        toast.error(err.message);
    }
}


function* deleteKeywordById(action: any): any {
    const values = action.payload;
    try {
        yield put(loaderSlice.actions.show());

        const res = yield call(deleteFeaturedLocationService, { ...values});

        const error = res?.data?.addFeaturedLocation?.error;
        const message = res?.data?.addFeaturedLocation?.message;


        if (error) {
            yield put(loaderSlice.actions.hide());
            toast.error(message);
        } else {
            yield put(loaderSlice.actions.hide());
            yield put(featuredLocationSlice.actions.getfeaturedLocationList(keywordListParams));
        }
    } catch (err: any) {
        yield put(loaderSlice.actions.hide());
        toast.error(err.message);
    }
}


function* updateFeaturedLocation(action: any): any {
    const values = action.payload;
    try {
        yield put(loaderSlice.actions.show());

        const res = yield call(updateFeaturedLocationService, { ...values});

        const error = res?.data?.addFeaturedLocation?.error;
        const message = res?.data?.addFeaturedLocation?.message;

        if (error) {
            yield put(loaderSlice.actions.hide());
            toast.error(message);
        } else {
            yield put(loaderSlice.actions.hide());
            toast.success('Featured Location has been successfully updated');
            yield put(featuredLocationSlice.actions.getfeaturedLocationList(keywordListParams));
        }
    } catch (err: any) {
        yield put(loaderSlice.actions.hide());
        toast.error(err.message);
    }
}


export default function* FeaturedLocationSaga() {
    yield takeLatest(CREATE_FEATURED_LOCATION, createFeaturedLocation);
    yield takeLatest(GET_FEATURED_LOCATION_LIST, getFeaturedLocationList)
    yield takeLatest(DELETE_FEATURED_LOCATION_BY_ID, deleteKeywordById);
    yield takeLatest(UPDATE_FEATURED_LOCATION, updateFeaturedLocation)
}